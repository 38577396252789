import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";
import Pagination from "../../components/pagination";

import api from "../../services/api";

export default () => {
  const [funds, setFunds] = useState();
  const [filter, setFilter] = useState({ search: "", page: 1, per_page: 20 });
  const [total, setTotal] = useState(0);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { data, total } = await api.post("/fund/search", {
        search: filter.search,
        page: filter.page,
        per_page: filter.per_page,
      });
      setFunds(data);
      setTotal(total);
    })();
  }, [filter]);

  if (!funds) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex gap-6 w-full justify-end">
          <SearchBar search={filter.search} setFilter={setFilter} />
          <Create />
        </div>
      </div>

      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden ">
                <thead className="bg-[#EFF2FE]">
                  <tr>
                    <th className="p-3 text-left text-primary-black-100 ">Name</th>
                    <th className="p-3 text-left text-primary-black-100 ">Region</th>
                    <th className="p-3 text-left text-primary-black-100 ">Currency</th>
                    <th className="p-3 text-left text-primary-black-100 ">Sector</th>
                    <th className="p-3 text-left text-primary-black-100 ">Created At</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {funds.map((e) => (
                    <tr key={e.email} className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`} onClick={() => history.push(`/fund/${e._id}`)}>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.name}</td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.region}</td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.currency}</td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.sector}</td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{(e.created_at || "")?.slice(0, 10)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-between items-center w-full p-3 text-primary-black-90 border-r border-primary-black-50 bg-[#EFF2FE] rounded-b-lg">
                <div>{`${(filter.page - 1) * filter.per_page + 1} à ${Math.min(filter.page * filter.per_page, total)} sur ${total} résultat(s)`}</div>
                <Pagination
                  per_page={filter.per_page}
                  total={total}
                  onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
                  onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
                  currentPage={filter.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const history = useHistory();

  async function onCreate() {
    const res = await api.post("/fund", values);
    toast.success("Created!");
    history.push(`/fund/${res.data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary whitespace-nowrap" onClick={() => setOpen(true)}>
        Create fund
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name</div>
          <input className="input block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>

        <button className="btn btn-primary disabled:opacity-80 disabled:cursor-not-allowed" disabled={!values.name} onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
