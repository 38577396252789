import React, { useState } from "react";

const BooleanIndicator = ({ value, title, textTrue, textFalse, icon }) => (
  <div className="w-full h-full relative rounded-3xl border border-gray-300  p-10 flex justify-center flex-col gap-5 z-10">
    {icon && <img src={icon} alt="map" className="absolute -z-10 -right-0 w-[260px] bottom-0" />}
    {value === "Yes" ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-10 mb-2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.707 6.707C11.8892 6.5184 11.99 6.2658 11.9877 6.0036C11.9854 5.7414 11.8802 5.49059 11.6948 5.30518C11.5094 5.11977 11.2586 5.0146 10.9964 5.01233C10.7342 5.01005 10.4816 5.11084 10.293 5.293L7 8.586L5.707 7.293C5.5184 7.11084 5.2658 7.01005 5.0036 7.01233C4.7414 7.0146 4.49059 7.11977 4.30518 7.30518C4.11977 7.49059 4.0146 7.7414 4.01233 8.0036C4.01005 8.2658 4.11084 8.5184 4.293 8.707L6.293 10.707C6.48053 10.8945 6.73484 10.9998 7 10.9998C7.26516 10.9998 7.51947 10.8945 7.707 10.707L11.707 6.707Z"
          fill="#22C55E"
        />
      </svg>
    ) : (
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16ZM7.207 5.293C7.0184 5.11084 6.7658 5.01005 6.5036 5.01233C6.2414 5.0146 5.99059 5.11977 5.80518 5.30518C5.61977 5.49059 5.5146 5.7414 5.51233 6.0036C5.51005 6.2658 5.61084 6.5184 5.793 6.707L7.086 8L5.793 9.293C5.69749 9.38525 5.62131 9.49559 5.5689 9.6176C5.51649 9.7396 5.4889 9.87082 5.48775 10.0036C5.4866 10.1364 5.5119 10.2681 5.56218 10.391C5.61246 10.5139 5.68671 10.6255 5.7806 10.7194C5.8745 10.8133 5.98615 10.8875 6.10905 10.9378C6.23194 10.9881 6.36362 11.0134 6.4964 11.0123C6.62918 11.0111 6.7604 10.9835 6.8824 10.9311C7.00441 10.8787 7.11475 10.8025 7.207 10.707L8.5 9.414L9.793 10.707C9.9816 10.8892 10.2342 10.99 10.4964 10.9877C10.7586 10.9854 11.0094 10.8802 11.1948 10.6948C11.3802 10.5094 11.4854 10.2586 11.4877 9.9964C11.49 9.7342 11.3892 9.4816 11.207 9.293L9.914 8L11.207 6.707C11.3892 6.5184 11.49 6.2658 11.4877 6.0036C11.4854 5.7414 11.3802 5.49059 11.1948 5.30518C11.0094 5.11977 10.7586 5.0146 10.4964 5.01233C10.2342 5.01005 9.9816 5.11084 9.793 5.293L8.5 6.586L7.207 5.293Z"
          fill="#F43F5E"
        />
      </svg>
    )}
    <h1 className="text-3xl font-semibold">{title}</h1>
    <p className="text-gray-600 text-lg">{value === "Yes" ? textTrue : textFalse}</p>
  </div>
);

export default BooleanIndicator;
