import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
// import { useHistory } from "react-router-dom";

// import { uuidv4 } from "./utils";

import api from "../../../services/api";
// import Rule from "./rule";

export default ({ indicator }) => {
  const [rules, setRules] = useState(indicator.question_rules || []);

  const onUpdate = async () => {
    const { data, ok } = await api.put(`/indicator/${indicator._id}`, {
      question_rules: rules,
    });
    if (!ok) return toast.error(data);
    toast.success("Updated!");
  };

  const handleValue = (value, index) => {
    const arr = [...rules];
    arr[index] = value;
    setRules(arr);
  };

  const handleRemove = (ruleIndex) => {
    setRules((state) => state.filter((e, index) => index !== ruleIndex));
  };

  const handleAdd = () => {
    setRules((state) => [...state, { action: "show" }]);
  };

  return (
    <div>
      {(rules || []).map((rule, k) => {
        return (
          <div key={k} className="border p-4 mt-4">
            <Rule
              value={rule}
              onChange={(obj) => handleValue(obj, k)}
              onRemove={() => handleRemove(k)}
              indicator={indicator}
            />
          </div>
        );
      })}
      <div className="flex items-center justify-end mt-2">
        <button className="btn bg-green-500" onClick={handleAdd}>
          Add rule
        </button>
      </div>

      <div className="mt-4">
        <button className="btn btn-primary" onClick={onUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};

const Rule = ({ value, onChange, onRemove, indicator }) => {
  return (
    <div>
      <div className="w-full flex items-center space-x-4">
        <div className="whitespace-nowrap">Display if Answer to : </div>
        <SelectQuestion
          indicator={indicator}
          value={value.question_id}
          onChange={(question_id) => onChange({ ...value, question_id })}
        />
        <div>is: </div>
        <select
          className="select"
          value={value.value}
          onChange={(e) => onChange({ ...value, value: e.target.value })}
        >
          <option selected disabled>
            Choose..
          </option>
          <option value={"true"}>True</option>
          <option value={"false"}>False</option>
        </select>
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <button className="btn bg-red-500" onClick={onRemove}>
          Delete
        </button>
      </div>
    </div>
  );
};

const SelectQuestion = ({ indicator, value, onChange }) => {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const { data, ok } = await api.post(`/indicator/search`, {
      dataset_id: indicator.dataset_id,
      question_type: "boolean",
    });
    setOptions(data);
  }

  if (!options) return <div>loading</div>;

  return (
    <div>
      <select
        className="select react-es-rule-field search-input"
        value={value}
        // value={fields.findIndex((e) => String(e.value) === String(field))}
        // onChange={(e) => setField(fields[e.target.value].value)}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((f, k) => {
          return (
            <option key={k} value={f._id}>
              {`${f.index} - ${f.question.en}`}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const defaultCombinators = [
  { value: "AND", text: "AND" },
  { value: "OR", text: "OR" },
];

const defaultOperators = [
  {
    value: "*",
    text: "contains",
    useInput: true,
    query: (key, value) =>
      value && query(key, value, (k, v) => ({ wildcard: { [k]: `*${v}*` } })),
  },
  {
    value: "!*",
    text: "does not contain",
    useInput: true,
    query: (key, value) =>
      value &&
      query(
        key,
        value,
        (k, v) => ({ bool: { must_not: { wildcard: { [k]: `*${v}*` } } } }),
        "must"
      ),
  },
  {
    value: "==",
    text: "is",
    useInput: true,
    query: (key, value) =>
      value && query(key, value, (k, v) => ({ term: { [k]: v } })),
  },
  {
    value: "!=",
    text: "is not",
    useInput: true,
    query: (key, value) =>
      value &&
      query(
        key,
        value,
        (k, v) => ({ bool: { must_not: { term: { [k]: v } } } }),
        "must"
      ),
  },
];

const defaultRule = {
  field: "",
  operator: defaultOperators[0].value,
  value: "",
  combinator: "AND",
  index: 0,
};
