import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";

import api from "../../../services/api";

import Loader from "../../../components/loader";

export default ({ campaign }) => {
  const [data, setData] = useState();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { data } = await api.post("/questionnaire/search", {
        campaign_id: campaign._id,
      });
      setData(data);
    })();
  }, []);

  if (!data) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-bold">Questionnaires</h3>
      </div>

      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="p-3 text-left text-h3-semibold text-primary-black-100"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-h3-semibold text-primary-black-100"
                    >
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {data.map((e) => (
                    <tr
                      key={e.email}
                      className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`}
                      onClick={() => history.push(`/questionnaire/${e._id}`)}
                    >
                      <td className="whitespace-nowrap p-3 text-sm text-primary-black-90">
                        {e.name}
                      </td>
                      <td className="whitespace-nowrap p-3 text-sm text-primary-black-90">
                        {(e.created_at || "")?.slice(0, 10)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
