import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import api from "../../../services/api";
import FileInput from "../../../components/FileInput";
import TextEditor from "../../../components/TextEditor";

export default ({ dataset }) => {
  const [values, setValues] = useState(dataset);

  const history = useHistory();

  const onUpdate = async () => {
    const { data } = await api.put(`/ressource/${dataset._id}`, values);
    toast.success("Updated!");
  };

  const onDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/ressource/${dataset._id}`);
    toast.success("Delete!");
    history.push("/website-dataset");
  };

  return (
    <div>
      <div className="w-[130px] aspect-square rounded-lg overflow-hidden border-muted-foreground">
        <FileInput value={values.logo} folder="/logo" onChange={(e) => setValues({ ...values, logo: e.target.value[0] })} />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Title</div>
          <input className="input" value={values.title?.en} onChange={(e) => setValues({ ...values, title: { ...values.title, en: e.target.value } })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Subtitle</div>
          <input className="input" value={values.subtitle?.en} onChange={(e) => setValues({ ...values, subtitle: { ...values.subtitle, en: e.target.value } })} />
        </div>
        <div className="w-full col-span-2">
          <div className="text-sm font-medium mb-2">Description</div>
          <textarea className="input" value={values.description?.en} onChange={(e) => setValues({ ...values, description: { ...values.description, en: e.target.value } })} />
        </div>
        <div className="w-full col-span-2">
          <div className="text-sm font-medium mb-2">Content</div>
          <div className="flex flex-col col-span-6">
            <p className=" text-xs mb-3 font-small p-2 rounded bg-gray-100 italic">
              Fill the content of a datasets section using the dropdown menu set to 'Normal'. If you wish to display a title select 'Heading Four'.
            </p>
          </div>
          <TextEditor
            value={values.content?.en}
            onChange={(e) =>
              setValues({
                ...values,
                content: { ...values.content, en: e.target.value },
              })
            }
          />
        </div>
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <button className="btn btn-primary" onClick={onUpdate}>
          Update
        </button>
        <button className="btn btn-red" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};
