import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";

import Loader from "../../components/loader";
import FileInput from "../../components/FileInput";

import api from "../../services/api";
import { APP_URL } from "../../config";

const INFORMATION = "INFORMATION";
const RAW_DATA = "RAW_DATA";

export default () => {
  const [partner, setPartner] = useState();
  const [tab, setTab] = useState(INFORMATION);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/user/${id}`);
        setPartner(data);
      } catch (e) {
        toast.error(e.message);
      }
    })();
  }, [id]);

  if (!partner) return <Loader />;

  return (
    <div className="grid md:grid-cols-12 w-full gap-x-4 gap-y-2">
      <div className="md:col-span-10">
        <nav className="flex items-center gap-2 pl-2">
          <button
            className={`${
              tab === INFORMATION && "bg-black-80 text-white"
            } transition-colors px-3 py-2 text-sm`}
            onClick={() => setTab(INFORMATION)}
          >
            Information
          </button>
          <button
            className={`${
              tab === RAW_DATA && "bg-black-80 text-white"
            } transition-colors px-3 py-2 text-sm`}
            onClick={() => setTab(RAW_DATA)}
          >
            Raw Data
          </button>
        </nav>
        <div className="bg-white rounded-md p-6 border border-primary-black-50">
          {tab === INFORMATION && <Information partner={partner} />}
          {tab === RAW_DATA && <RawData partner={partner} />}
        </div>
      </div>
      <div className="md:col-span-2 md:space-y-10 space-y-4 self-center row-start-1 md:row-start-auto">
        <a
          href={`${APP_URL}/loginas?id=${partner._id}&token=${api.getToken()}`}
          target="_blank"
          className="py-3 px-6 border border-app text-sm text-app font-bold rounded-lg md:w-auto w-full"
        >
          Log in as
        </a>
      </div>
    </div>
  );
};

const Information = ({ partner }) => {
  const [values, setValues] = useState(partner);

  const history = useHistory();

  const onUpdate = async () => {
    const { data } = await api.put(`/user/${partner._id}`, values);
    toast.success("Updated!");
  };

  const onDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/user/${partner._id}`);
    toast.success("Delete!");
    history.push("/partner");
  };

  return (
    <div>
      <div className="mb-6">
        <FileInput
          value={values.avatar}
          onChange={async (e) => {
            const { data } = await api.put(`/user/${values._id}`, {
              avatar: e.target.value[0],
            });
            setValues(data);
            toast.success("Updated!");
          }}
          folder={`/user`}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Name</div>
          <input
            className="input"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Email Address</div>
          <input
            className="input"
            value={values.email}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
            placeholder="Email address"
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Phone</div>
          <input
            className="input"
            value={values.phone}
            onChange={(e) => setValues({ ...values, phone: e.target.value })}
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Language</div>
          <select
            className="select input"
            value={values.language}
            onChange={(e) => setValues({ ...values, language: e.target.value })}
          >
            <option value="English">English</option>
          </select>
        </div>
        <div className="flex items-center space-x-2 mt-4">
          <button className="btn btn-primary" onClick={onUpdate}>
            Update
          </button>
          <button className="btn btn-red" onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const RawData = ({ partner }) => {
  return (
    <div className="">
      <pre> {JSON.stringify(partner, null, 2)}</pre>
    </div>
  );
};

const Select = ({ value, onChange, data }) => {
  return (
    <select
      className="select input"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value={""}>Select...</option>
      {data.map((item, i) => (
        <option value={item} key={i}>
          {item}
        </option>
      ))}
    </select>
  );
};
