import React, { useEffect, useState } from "react";

import api from "../services/api";

export default ({ value, onChange, fund_id }) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    get();
  }, [fund_id]);

  useEffect(() => {
    if (companies.length === 1) {
      onChange(companies[0]);
    }
  }, [companies]);

  async function get() {
    const query = {};
    if (fund_id) query.fund_id = fund_id;
    const res = await api.post("/company/search", query);
    setCompanies(res.data);
  }

  return (
    <div>
      <select
        className="select bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] cursor-pointer shadow-sm"
        name="project"
        value={value || ""}
        onChange={(e) => {
          e.preventDefault();
          const f = companies.find((f) => e.target.value === f.name);
          onChange(f);
        }}
      >
        <option disabled>Company</option>
        {companies
          .sort(function (a, b) {
            if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
            if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
            return 0;
          })
          .map((e) => {
            return (
              <option key={e._id} value={e.name}>
                {e.name}
              </option>
            );
          })}
      </select>
    </div>
  );
};
