import React, { useState, useEffect } from "react";
import RichTextEditor from "react-rte";

const TextEditor = ({ title, onChange, reload, value, className, name = "default" }) => {
  const [text, setText] = useState("");

  // initialize the value of the text editor
  // the text editor is buggy when used direct state
  useEffect(() => {
    setText(value);
  }, [reload]);

  return (
    <div>
      {title && <div className="text-base font-medium mb-2">{title}</div>}
      <Editor value={text} onChange={onChange} name={name} className={className} />
    </div>
  );
};

const Editor = ({ onChange, value, className, name = "default" }) => {
  const [str, setStr] = useState(null);

  useEffect(() => {
    setStr(RichTextEditor.createValueFromString(value || "", "html"));
  }, [value]);

  function update(v) {
    setStr(v);
    if (onChange) onChange({ target: { value: v.toString("html"), name } });
  }

  if (str === null) return <div>Loading ...</div>;

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Four", style: "header-four" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  return (
    <div className="w-full" style={{ height: "100%" }}>
      <RichTextEditor style={{ height: "300px" }} name={name} onChange={update} value={str} className={className + " richtexteditor input"} toolbarConfig={toolbarConfig} />
    </div>
  );
};

export default TextEditor;
