import React, { useEffect, useState } from "react";

const Dashboard = () => {
  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Dashboard</h3>
    </div>
  );
};

export default Dashboard;
