import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from "react-router-dom";

import Loader from "./components/loader";
import Layout from "./components/Layout";

import Auth from "./scenes/auth";
import Dashboard from "./scenes/dashboard";
import Account from "./scenes/account";
import User from "./scenes/user";
import Partner from "./scenes/partner";
import Company from "./scenes/company";
import Campaign from "./scenes/campaign";
import Fund from "./scenes/fund";
import Dataset from "./scenes/dataset";
import Questionnaire from "./scenes/questionnaire";
import WebsiteDataset from "./scenes/website-dataset";

import api from "./services/api";
import { setUser } from "./redux/auth/actions";

const App = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.get("/admin/signin_token");
        if (!res.ok || !res.user) return setLoading(false);
        if (res.token) api.setToken(res.token);
        dispatch(setUser(res.user));
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  if (loading) return <Loader />;

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/auth" component={Auth} />
        <Layout className="w-full">
          <RestrictedRoute path="/user" component={User} />
          <RestrictedRoute path="/fund" component={Fund} />
          <RestrictedRoute path="/campaign" component={Campaign} />
          <RestrictedRoute path="/company" component={Company} />
          <RestrictedRoute path="/partner" component={Partner} />
          <RestrictedRoute path="/dataset" component={Dataset} />
          <RestrictedRoute path="/website-dataset" component={WebsiteDataset} />
          <RestrictedRoute path="/account" component={Account} />
          <RestrictedRoute path="/questionnaire" component={Questionnaire} />
          <RestrictedRoute exact path="/" component={Dashboard} />
        </Layout>
      </Switch>
    </Router>
  );
};

const RestrictedRoute = ({ component: Component, role, ...rest }) => {
  const user = useSelector((state) => state.Auth.user);

  if (!user || (role === "admin" && user.role !== "admin")) return <Redirect to={{ pathname: "/auth" }} />;

  return <Route {...rest} render={(props) => (user ? <Component {...props} /> : <Redirect to={{ pathname: "/auth" }} />)} />;
};

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
