import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import api from "../../../services/api";
import FileInput from "../../../components/FileInput";
import SelectFund from "../../../components/SelectFund";

export default ({ dataset }) => {
  const [values, setValues] = useState(dataset);

  const history = useHistory();

  const onUpdate = async () => {
    const { data } = await api.put(`/dataset/${dataset._id}`, values);
    toast.success("Updated!");
  };

  const onDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/dataset/${dataset._id}`);
    toast.success("Delete!");
    history.push("/dataset");
  };

  return (
    <div>
      <div className="w-[130px] aspect-square rounded-lg overflow-hidden border-muted-foreground">
        <FileInput value={values.logo} folder="/logo" onChange={(e) => setValues({ ...values, logo: e.target.value[0] })} />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Name</div>
          <input className="input" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Type</div>
          <select className="select input" value={values.type} onChange={(e) => setValues({ ...values, type: e.target.value })}>
            <option value="custom">Custom</option>
            <option value="master">Master</option>
          </select>
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Fund</div>
          <SelectFund
            value={values.fund_name}
            onChange={(e) =>
              setValues({
                ...values,
                fund_name: e.name,
                fund_id: e._id,
              })
            }
          />
        </div>
        <div className="w-full col-span-2">
          <div className="text-sm font-medium mb-2">Description</div>
          <textarea className="input" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
        </div>
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <button className="btn btn-primary" onClick={onUpdate}>
          Update
        </button>
        <button className="btn btn-red" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};
