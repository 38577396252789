import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";

import api from "../../services/api";

export default () => {
  const [partners, setPartners] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
  });

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const fund_id = urlParams.get("fund_id");
      const { data } = await api.post("/user/search", {
        fund_id,
        role: "partner",
        search: filter.search,
      });
      setPartners(data);
    })();
  }, [filter]);

  if (!partners) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-bold">Partners</h3>
        <div className="flex gap-6 w-full justify-end">
          <SearchBar search={filter.search} setFilter={setFilter} />
          {/* <Create /> */}
        </div>
      </div>

      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="p-3 text-left text-h3-semibold text-primary-black-100"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-h3-semibold text-primary-black-100"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-h3-semibold text-primary-black-100"
                    >
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {partners.map((e) => (
                    <tr
                      key={e.email}
                      className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`}
                      onClick={() => history.push(`/partner/${e._id}`)}
                    >
                      <td className="whitespace-nowrap p-3 text-sm text-primary-black-90">
                        {e.name}
                      </td>
                      <td className="whitespace-nowrap p-3 text-sm text-primary-black-90">
                        {e.email}
                      </td>
                      <td className="whitespace-nowrap p-3 text-sm text-primary-black-90">
                        {(e.created_at || "")?.slice(0, 10)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const history = useHistory();

  async function onCreate() {
    values.role = "partner";
    const res1 = await api.post("/user/invite", values);

    values.user_id = res1.data._id;
    values.user_name = res1.data.name;
    const res = await api.post("/partner", values);

    toast.success("Created!");
    history.push(`/partner/${res1.data._id}`);
  }

  return (
    <div>
      <button
        className="btn btn-primary whitespace-nowrap"
        onClick={() => setOpen(true)}
      >
        Create partner
      </button>

      <Modal
        isOpen={open}
        className="max-w-xl w-full p-6"
        onClose={() => setOpen(false)}
      >
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name</div>
          <input
            className="input block"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
        </div>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Email</div>
          <input
            className="input block"
            type="email"
            value={values.email}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
          />
        </div>

        <button
          className="btn btn-primary disabled:opacity-80 disabled:cursor-not-allowed"
          disabled={!values.name}
          onClick={onCreate}
        >
          Create
        </button>
      </Modal>
    </div>
  );
};
