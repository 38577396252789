import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useHistory, Link } from "react-router-dom";

import Loader from "../../components/loader";
import UserTagSelector from "../../components/userSelector";
import Modal from "../../components/modal";
import FileInput from "../../components/FileInput";

import { ASSET_CLASSES, BUSINESS_SECTOR, CURRENCY, DOMICILE, NACE_CODE } from "../../constants";

import api from "../../services/api";
import { APP_URL } from "../../config";
import MultiSelect from "../../components/MultiSelect";

const INFORMATION = "INFORMATION";
const RAW_DATA = "RAW_DATA";

export default () => {
  const [fund, setFund] = useState();
  const [tab, setTab] = useState(INFORMATION);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/fund/${id}`);
        setFund(data);
      } catch (e) {
        toast.error(e.message);
      }
    })();
  }, [id]);

  if (!fund) return <Loader />;

  return (
    <div className="grid md:grid-cols-12 w-full gap-x-4 gap-y-2">
      <div className="md:col-span-8">
        <nav className="flex items-center gap-2 pl-2">
          <button className={`${tab === INFORMATION && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(INFORMATION)}>
            Information
          </button>
          <button className={`${tab === RAW_DATA && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(RAW_DATA)}>
            Raw Data
          </button>
        </nav>
        <div className="bg-white rounded-md p-6 border border-app-black-50">
          {tab === INFORMATION && <Information fund={fund} />}
          {tab === RAW_DATA && <RawData fund={fund} />}
        </div>
      </div>
      <div className="md:col-span-4 md:space-y-10 space-y-4 row-start-1 md:row-start-auto">
        <div className="bg-white rounded-lg border mb-2 divide-y">
          <div className="py-4 px-5">
            <Link className="text-sm text-app underline" to={`/user?fund_id=${fund._id}`}>
              See users
            </Link>
          </div>
          <div className="py-4 px-5">
            <Link className="text-sm text-app underline" to={`/user?fund_id=${fund._id}&role=partner`}>
              See partners
            </Link>
          </div>
          <div className="py-4 px-5">
            <Link className="text-sm text-app underline" to={`/company?fund_id=${fund._id}`}>
              See companies
            </Link>
          </div>
          <div className="py-4 px-5">
            <Link className="text-sm text-app underline" to={`/campaign?fund_id=${fund._id}`}>
              See campaigns
            </Link>
          </div>
          <div className="py-4 px-5">
            <Link className="text-sm text-app underline" to={`/dataset?fund_id=${fund._id}`}>
              See datasets
            </Link>
          </div>
          <div className="py-4 px-5">
            <Link className="text-sm text-app underline" to={`/questionnaire?fund_id=${fund._id}`}>
              See questionnaires
            </Link>
          </div>
        </div>
        <Invite fund={fund} setFund={setFund} />
      </div>
    </div>
  );
};

const Information = ({ fund }) => {
  const [values, setValues] = useState(fund);
  const [selectedNaceCodes, setSelectedNaceCodes] = useState([]);
  const initialSelectedNaceCodesRef = useRef(true);

  const history = useHistory();

  const onUpdate = async () => {
    const { data } = await api.put(`/fund/${fund._id}`, values);
    toast.success("Updated!");
  };

  const onDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/fund/${fund._id}`);
    toast.success("Delete!");
    history.push("/fund");
  };

  useEffect(() => {
    if (initialSelectedNaceCodesRef.current) {
      initialSelectedNaceCodesRef.current = false;
      if (Array.isArray(values.nace_codes)) {
        const tempSelectedNaceCodes = values.nace_codes.map((codeString) => {
          const [code, name] = codeString.split(" - ");
          const naceCode = NACE_CODE.find((item) => item.code === code && item.name === name);
          return naceCode ? naceCode : null;
        });
        setSelectedNaceCodes(tempSelectedNaceCodes);
      }
    }
  }, [values.nace_codes]);

  useEffect(() => {
    if (Array.isArray(selectedNaceCodes)) {
      const tempNaceCodes = selectedNaceCodes.map((naceCode) => `${naceCode.code} - ${naceCode.name}`);
      setValues({ ...values, nace_codes: tempNaceCodes });
    }
  }, [selectedNaceCodes]);

  const handleSelectedNaceCode = (e) => {
    const selectedNaceCodes = [];
    e.forEach((codeString) => {
      const section = codeString.value?.substring(0, 1);
      if (codeString.value?.length > 2) {
        const code = codeString.value?.substring(1);
        const naceCode = NACE_CODE.find((item) => item.section === section && item.code === code);

        if (naceCode) {
          selectedNaceCodes.push(naceCode);
        }
      } else {
        const naceCode = NACE_CODE.find((item) => item.section === section && item.code === "");

        if (naceCode) {
          selectedNaceCodes.push(naceCode);
        }
      }
    });
    setSelectedNaceCodes(selectedNaceCodes);
  };

  return (
    <div>
      <div className="mb-6">
        <FileInput
          value={values.logo}
          onChange={async (e) => {
            const { data } = await api.put(`/fund/${fund._id}`, {
              logo: e.target.value[0],
            });
            setValues(data);
            toast.success("Updated!");
          }}
          folder={`/fund`}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Name</div>
          <input className="input" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Vintage year</div>
          <input
            className="input"
            type="date"
            value={values?.vintage_at && new Date(values?.vintage_at).toISOString().split("T")[0]}
            onChange={(e) => setValues({ ...values, vintage_at: e.target.value })}
            name="vintage_at"
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Currency</div>
          <Select value={values.currency} onChange={(currency) => setValues({ ...values, currency })} data={CURRENCY.map(({ code, name }) => `${code} - ${name}`)} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Legal Structure</div>
          <input
            className="input"
            placeholder="Structure Code"
            value={values.structure_code}
            onChange={(e) => setValues({ ...values, structure_code: e.target.value })}
            name="structure_code"
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Sector</div>
          <Select value={values.sector} onChange={(sector) => setValues({ ...values, sector })} data={BUSINESS_SECTOR} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">General partner</div>
          <PartnerSelector
            value={values?.partner_id}
            onChange={(partner) =>
              setValues({
                ...values,
                partner_id: partner._id,
                partner_name: partner.name,
                partner_email: partner.email,
              })
            }
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Domicile of Origin</div>
          <Select value={values.region} onChange={(region) => setValues({ ...values, region })} data={DOMICILE.map(({ code, name }) => `${code} - ${name}`)} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Asset Class</div>
          <Select value={values.asset_class} onChange={(asset_class) => setValues({ ...values, asset_class })} data={ASSET_CLASSES} />
        </div>
        {values?.asset_class && (
          <div className="w-full col-span-2">
            <div className="text-sm font-medium mb-2">Asset Class Description</div>
            <input className="input" value={values.asset_class_description} onChange={(e) => setValues({ ...values, asset_class_description: e.target.value })} />
          </div>
        )}
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Website</div>
          <input className="input" value={values.website} onChange={(e) => setValues({ ...values, website: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">investment strategy</div>
          <input className="input" value={values.investment_strategy} onChange={(e) => setValues({ ...values, investment_strategy: e.target.value })} />
        </div>
        <div className="w-full col-span-2">
          <div className="text-sm font-medium mb-2">NACE code(s) - Enter to add other NACE code(s)</div>
          <MultiSelect
            width="w-full"
            naceCode={true}
            values={selectedNaceCodes?.map((naceCode) => ({ value: `${naceCode.section}${naceCode.code}` }))}
            onSelectedChange={handleSelectedNaceCode}
            placeholder="Select..."
            options={NACE_CODE.map((nc) => ({ value: `${nc.section}${nc.code}`, label: `${nc.code} ${nc.name}` }))}
            renderOption={(item) => <div>{item}</div>}
          />
        </div>
      </div>

      <div className="flex items-center space-x-2 mt-4">
        <button className="btn btn-primary" onClick={onUpdate}>
          Update
        </button>
        <button className="btn btn-red" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

const RawData = ({ fund }) => {
  return (
    <div className="">
      <pre> {JSON.stringify(fund, null, 2)}</pre>
    </div>
  );
};

const Select = ({ value, onChange, data }) => {
  return (
    <select className="select input" value={value} onChange={(e) => onChange(e.target.value)}>
      <option value={""}>Select...</option>
      {data.map((item, i) => (
        <option value={item} key={i}>
          {item}
        </option>
      ))}
    </select>
  );
};

const PartnerSelector = ({ value, onChange }) => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/partner");
      setPartners(data);
    })();
  }, []);
  return (
    <select className="select input" value={value} onChange={(e) => onChange(partners.find((p) => p._id === e.target.value))}>
      <option value={""}>Select...</option>
      {partners.map((item, i) => (
        <option value={item._id} key={item._id}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

const Invite = ({ fund, setFund }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ name: "", email: "" });
  const [tab, setTab] = useState("email");
  const link = `${APP_URL}/auth/signup_partner?fund_token=${fund.invitation_token}`;

  const onSubmit = async () => {
    values.fund_id = fund._id;
    values.fund_name = fund.name;
    values.role = "partner";

    const { data } = await api.post("/user/invite", values);
    toast.success("Created!");
    setOpen(false);
  };

  const toggleInvitation = async () => {
    const { data, ok } = await api.post(`/fund/toggle_invitation_link/${fund._id}`);
    if (!ok) return toast.error("Error");
    setFund(data);
  };

  return (
    <>
      <button onClick={() => setOpen(true)} className="py-3 px-6 border border-app text-sm text-app font-bold rounded-lg md:w-auto w-full">
        Invite partner
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="max-w-2xl rounded-xl">
        <div className="p-6 ">
          <div className="text-xl font-bold mb-4">Invite a new partner</div>
          <ul className="flex items-center gap-x-5 border-b border-gray-200 mb-4">
            <li>
              <div
                onClick={() => setTab("email")}
                className={`cursor-pointer text-sm font-medium pb-3 border-b-2 transition-colors hover:border-purple-2 hover:text-purple-2 capitalize ${
                  tab === "email" ? "border-purple-2 text-purple-2" : "border-transparent text-muted-foreground"
                }`}>
                Invite with email
              </div>
            </li>
            <li>
              <div
                onClick={() => setTab("link")}
                className={`cursor-pointer text-sm font-medium pb-3 border-b-2 transition-colors hover:border-purple-2 hover:text-purple-2 capitalize ${
                  tab === "link" ? "border-purple-2 text-purple-2" : "border-transparent text-muted-foreground"
                }`}>
                Invite with link
              </div>
            </li>
          </ul>
          {tab === "email" && (
            <>
              <div className="grid grid-cols-2 gap-4">
                <div className="mb-4">
                  <div className="text-sm text-black/90 mb-2">Name</div>
                  <input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} className="border border-gray-300 rounded-lg w-full px-4 py-2" />
                </div>
                <div className="mb-4">
                  <div className="text-sm text-black/90 mb-2">Email</div>
                  <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} className="border border-gray-300 rounded-lg w-full px-4 py-2" />
                </div>
              </div>
              <button disabled={!values.name || !values.email} onClick={onSubmit} className="bg-app py-3 px-6 mt-4 text-sm rounded-md text-white">
                Send invite
              </button>
            </>
          )}
          {tab === "link" && (
            <div className="space-y-4">
              {fund.invitation_token ? (
                <div>
                  <textarea className="bg-gray-100 border border-gray-300 rounded p-2" readOnly value={link} />
                  <div className="flex gap-10">
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(link);
                        toast.success("Link copied to clipboard");
                      }}
                      className="bg-app py-3 px-6 mt-4 text-sm rounded-md text-white">
                      Copy link
                    </button>
                    <button onClick={toggleInvitation} className="bg-red-500 py-3 px-6 mt-4 text-sm rounded-md text-white">
                      Delete link
                    </button>
                  </div>
                </div>
              ) : (
                <button onClick={toggleInvitation} className="bg-app py-3 px-6 mt-4 text-sm rounded-md text-white">
                  Generate link
                </button>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
