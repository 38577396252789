import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";

import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import Modal from "../../../components/modal";
import SearchBar from "../../../components/searchBar";
import Pagination from "../../../components/pagination";
import { dimensions } from "../../../constants";
import { question_types } from "../../../constants";
import api from "../../../services/api";

import View from "../../../components/indicator";

export default ({ dataset, setDataset }) => {
  const [indicators, setIndicators] = useState();
  const [filter, setFilter] = useState({ page: 1, per_page: 500 });
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [view, setView] = useState();

  const currentPage = filter.page;

  useEffect(() => {
    get();
  }, [filter]);

  async function get() {
    const nf = { ...filter, dataset_id: dataset._id };
    const { data, total } = await api.post("/indicator/search", nf);
    setIndicators(data);
    setTotal(total);
  }

  const handleChange = () => {
    get();
  };

  const onSelectAll = () => {
    setSelectAll((state) => !state);
    if (!selectAll) {
      const newArr = [...selected];
      for (let i = 0; i < indicators.length; i++) {
        if (!newArr.find((e) => e._id === indicators[i]._id)) {
          newArr.push(indicators[i]);
        }
      }
      setSelected(newArr);
    } else {
      setSelected([]);
    }
  };

  function onSelect(v) {
    if (selectAll) setSelectAll(false);
    let arr = [...selected];
    if (arr.find((item) => item._id === v._id)) arr = arr.filter((item) => item._id !== v._id);
    else arr = [...selected, v];
    setSelected(arr);
  }

  const handleDeleteMultiple = async () => {
    for (let i = 0; i < selected.length; i++) {
      try {
        const indicator = selected[i];
        await api.remove(`/indicator/${indicator._id}`);
      } catch (e) {
        console.log(e);
      }
      toast.success("Done!");
    }
    get();
    setSelected([]);
  };

  if (!indicators) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-bold w-full">{dataset.name} </h3>

        <div className="flex gap-6 w-full justify-end">
          <SearchBar search={filter.search} setFilter={setFilter} />
          <Create dataset={dataset} />
          {selected.length !== 0 && (
            <button className="bg-red-400 border-red-400 btn btn-primary whitespace-nowrap" onClick={handleDeleteMultiple}>
              Delete
            </button>
          )}
        </div>
      </div>

      <View datasetId={dataset._id} value={view} handleChange={handleChange} onChange={(e) => setView(null)} />
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden table-auto">
                <thead className="bg-[#EFF2FE]">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100" onClick={dataset.type === "custom" ? onSelectAll : null}>
                      <div className="flex items-center justify-center">
                        {dataset.type === "custom" && (
                          <label className={`cursor-pointer w-5 h-5 flex items-center justify-center rounded border mr-1 border-gray-300 ${selectAll && "bg-primary"}`}>
                            <AiOutlineCheck className="text-white text-xs" />
                          </label>
                        )}
                        <p>Key</p>
                      </div>
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Dimension
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Sub Dimension
                    </th>

                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Indicator
                    </th>

                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Question type
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Regulation
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Flag rule
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      NACE code
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {indicators.map((e) => {
                    const checked = selected.some((s) => s._id === e._id);
                    return (
                      <tr key={e._id} className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`} onClick={() => setView(e)}>
                        <td
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-black-90 border-x border-primary-black-50"
                          onClick={(event) => {
                            if (dataset.type === "custom") {
                              event.stopPropagation();
                              onSelect(e);
                            }
                          }}>
                          <div className="flex items-center justify-center">
                            {dataset.type === "custom" && (
                              <label className={`cursor-pointer w-5 h-5 flex items-center justify-center rounded border mr-1 border-gray-300 ${checked && "bg-primary"}`}>
                                <AiOutlineCheck className="text-white text-xs" />
                              </label>
                            )}

                            <p>{e.key}</p>
                          </div>
                        </td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                          <p className={`text-center px-1 py-2 rounded font-medium ${dimensions.find((d) => d.name === e.dimension)?.color || "bg-gray-300"}`}>{e.dimension}</p>
                        </td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.sub_dimension}</td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50 max-w-md">{e.question?.en}</td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                          <div className="flex justify-start items-center">
                            <p
                              className={`w-max text-center px-4 py-2 rounded text-app-gray font-semibold ${
                                question_types.find((q_type) => q_type.name === e.question_type)?.color || "bg-gray-300"
                              }`}>
                              {e.question_type}
                            </p>
                          </div>
                        </td>
                        <td className="p-3 text-sm text-center text-primary-black-90 border-r border-primary-black-50">
                          <p>{e.regulation ? e.regulation : "-"}</p>
                        </td>
                        <td className="p-3 text-sm text-center text-primary-black-90 border-r border-primary-black-50">
                          <p>{e.flag_rule ? e.flag_rule : "-"}</p>
                        </td>
                        <td className="p-3 text-sm text-center text-primary-black-90 border-r border-primary-black-50">
                          <p>{e.nace_code ? e.nace_code : "-"}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="flex justify-between flex-row p-3 text-primary-black-90 border-r border-primary-black-50 bg-[#EFF2FE] rounded-b-lg">
                <div className="justify-center">{` ${(currentPage - 1) * filter.per_page + 1} à ${Math.min(currentPage * filter.per_page, total)} sur ${total} résultat(s)`}</div>
                <Pagination
                  per_page={filter.per_page}
                  total={total}
                  onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
                  onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
                  currentPage={filter.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = (dataset) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    dataset_id: dataset.dataset._id,
    question_type: "text",
  });

  async function onCreate() {
    const { ok } = await api.post("/indicator", values);
    if (!ok) return toast.error("Error!");
    toast.success("Created!");
    window.location.reload();
    setOpen(false);
  }

  return (
    <div>
      <button className="btn btn-primary whitespace-nowrap" onClick={() => setOpen(true)}>
        Create indicator
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name</div>
          <input className="input block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>

        <button className="btn btn-primary disabled:opacity-80 disabled:cursor-not-allowed" disabled={!values.name} onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
