import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import dayjs from "dayjs";

import Loader from "../../components/loader";

import api from "../../services/api";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";
import Pagination from "../../components/pagination";
import SelectFund from "../../components/SelectFund";
import MultiSelect from "../../components/MultiSelect";

export default () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const [datasets, setDatasets] = useState();
  const [funds, setFunds] = useState();
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 20,
    funds: urlParams.get("fund_id") ? [urlParams.get("fund_id")] : [],
  });

  async function getFunds() {
    const { data, ok } = await api.post("/fund/search");
    if (!ok) return toast.error("Error!");
    setFunds(data);
  }

  useEffect(() => {
    getFunds();
  }, []);

  useEffect(() => {
    get();
  }, [filter]);

  async function get() {
    
    const { data, total } = await api.post("/dataset/search", {
      search: filter.search,
      per_page: filter.per_page,
      page: filter.page,
      funds: filter.funds,
    });
    setDatasets(data);
    setTotal(total);
  }

  if (!datasets || !funds) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between space-x-6">
        <div className="flex gap-6 w-full justify-end">
          <MultiSelect
            values={filter.funds?.map((fundId) => ({ value: fundId }))}
            onSearch={(search) => setFilter({ ...filter, search })}
            onSelectedChange={(e) => {
              const ids = e.map((e) => e.value);
              setFilter({ ...filter, funds: ids });
            }}
            placeholder="Funds"
            options={funds.map((f) => ({ value: f._id, label: f.name }))}
            renderOption={(item) => <div>{item}</div>}
          />
          <SearchBar search={filter.search} setFilter={setFilter} />
          <Create />
          <CreateCustom />
        </div>
      </div>

      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden ">
                <thead className="bg-[#EFF2FE]">
                  <tr>
                    <th className="p-3 text-left text-primary-black-100 ">
                      Name
                    </th>
                    <th className="p-3 text-left text-primary-black-100 ">
                      Fund
                    </th>
                    <th className="p-3 text-left text-primary-black-100 ">
                      Type
                    </th>
                    <th className="p-3 text-left text-primary-black-100 ">
                      Master file
                    </th>
                    <th className="p-3 text-left text-primary-black-100">
                      Created at
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {datasets
                    .sort((a, b) =>
                      a.type === "master" && b.type === "custom" ? -1 : 1
                    )
                    .map((e) => (
                      <tr
                        key={e._id}
                        className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`}
                        onClick={() => {
                          history.push(`/dataset/${e._id}`);
                        }}
                      >
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                          {e.name}
                        </td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                          {e.fund_name || "-"}
                        </td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                          {e.type}
                        </td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                          {e.master_dataset_name}
                        </td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                          {dayjs(e.created_at).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="flex justify-between items-center w-full p-3 text-primary-black-90 border-r border-primary-black-50 bg-[#EFF2FE] rounded-b-lg">
                <div>{`${(filter.page - 1) * filter.per_page + 1} à ${Math.min(
                  filter.page * filter.per_page,
                  total
                )} sur ${total} résultat(s)`}</div>
                <Pagination
                  per_page={filter.per_page}
                  total={total}
                  onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
                  onPrevious={() =>
                    setFilter((f) => ({ ...f, page: +f.page - 1 }))
                  }
                  currentPage={filter.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  const history = useHistory();

  async function onCreate() {
    values.type = "master";
    const res = await api.post("/dataset", values);
    toast.success("Created!");
    history.push(`/dataset/${res.data._id}`);
  }

  return (
    <div>
      <button
        className="btn btn-primary whitespace-nowrap"
        onClick={() => setOpen(true)}
      >
        New master file
      </button>

      <Modal
        isOpen={open}
        className="max-w-xl w-full p-6"
        onClose={() => setOpen(false)}
      >
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name</div>
          <input
            className="input block"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
        </div>

        <button
          className="btn btn-primary disabled:opacity-80 disabled:cursor-not-allowed"
          disabled={!values.name}
          onClick={onCreate}
        >
          Create
        </button>
      </Modal>
    </div>
  );
};

const CreateCustom = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  const history = useHistory();

  async function onCreate() {
    values.type = "custom";
    const res = await api.post("/dataset", values);
    toast.success("Created!");
    history.push(`/dataset/${res.data._id}`);
  }

  return (
    <div>
      <button
        className="btn btn-primary whitespace-nowrap"
        onClick={() => setOpen(true)}
      >
        New custom file
      </button>

      <Modal
        isOpen={open}
        className="max-w-xl w-full p-6"
        onClose={() => setOpen(false)}
      >
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name</div>
          <input
            className="input block"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
        </div>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Master Dataset</div>
          <DatasetSelect
            value={values.master_dataset_name}
            onChange={(e) =>
              setValues({
                ...values,
                master_dataset_name: e.name,
                master_dataset_id: e._id,
              })
            }
          />
        </div>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Fund</div>
          <SelectFund
            value={values.fund_name}
            onChange={(e) =>
              setValues({
                ...values,
                fund_name: e.name,
                fund_id: e._id,
              })
            }
          />
        </div>

        <button
          className="btn btn-primary disabled:opacity-80 disabled:cursor-not-allowed"
          disabled={!values.name || !values.master_dataset_id}
          onClick={onCreate}
        >
          Create
        </button>
      </Modal>
    </div>
  );
};

const DatasetSelect = ({ value, onChange }) => {
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const res = await api.post("/dataset/search", { type: "master" });
    setDatasets(res.data);
  }

  return (
    <div>
      <select
        className="select w-full bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] cursor-pointer shadow-sm"
        name="project"
        value={value || ""}
        onChange={(e) => {
          e.preventDefault();
          const f = datasets.find((f) => e.target.value === f.name);
          onChange(f);
        }}
      >
        <option disabled>Datasets</option>
        {datasets
          .sort(function (a, b) {
            if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
            if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
            return 0;
          })
          .map((e) => {
            return (
              <option key={e.name} value={e.name}>
                {e.name}
              </option>
            );
          })}
      </select>
    </div>
  );
};
