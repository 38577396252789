import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const COLORS = ["#99F6E4", "#2DD4BF", "#1CAFAA", "#0A8F95", "#006F80"];

const PieIndicator = ({ title, values, colors = COLORS }) => (
  <div className="w-full rounded-3xl border border-gray-300 p-10 flex justify-center flex-col gap-5">
    <h1 className="text-2xl font-semibold text-center">{title}</h1>
    <div className="w-full h-72 flex items-center justify-center">
      <Pie
        data={{
          labels: values.map((v) => v.label),
          datasets: [
            {
              data: values.map((v) => v.value),
              backgroundColor: values.map((_, i) => colors[i]),
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
    <div className="flex flex-col gap-3">
      {values.map((v, i) => (
        <div key={i} className="flex gap-3 items-center justify-center">
          <div className="w-5 h-5 rounded-full" style={{ backgroundColor: colors[i] }}></div>
          <p>
            {v.label} - {v.value}%
          </p>
        </div>
      ))}
    </div>
  </div>
);

export default PieIndicator;
