import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Loader from "../../../components/loader";

import api from "../../../services/api";

export default ({ campaign }) => {
  const [campaignIndicators, setCampaignIndicators] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const { data } = await api.post("/campaign_indicator/search", {
      campaign_id: campaign._id,
    });
    setCampaignIndicators(data);
  }

  if (!campaignIndicators) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row gap-5 items-center justify-between mb-10">
        <h3 className="text-xl font-bold shrink-0">Campaign Indicators</h3>
      </div>

      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="p-3 text-left text-h3-semibold text-primary-black-100"
                    >
                      Index
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-h3-semibold text-primary-black-100"
                    >
                      Indicator
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-h3-semibold text-primary-black-100"
                    >
                      Dimension
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-h3-semibold text-primary-black-100"
                    >
                      Subdimension
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-h3-semibold text-primary-black-100"
                    >
                      Answered
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {campaignIndicators.map((e, index) => (
                    <tr
                      key={e.email}
                      className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`}
                      onClick={() => {
                        toast.success("Clicked!");
                        // history.push(`/partner/${e._id}`);
                      }}
                    >
                      <td className="whitespace-nowrap p-3 text-sm text-primary-black-90">
                        {index}
                      </td>
                      <td className="whitespace-nowrap p-3 text-sm text-primary-black-90">
                        {e.key}
                      </td>
                      <td className="whitespace-nowrap p-3 text-sm text-primary-black-90">
                        {e.dimension}
                      </td>
                      <td className="whitespace-nowrap p-3 text-sm text-primary-black-90">
                        {e.sub_dimension}
                      </td>
                      <td className="whitespace-nowrap p-3 text-sm text-primary-black-90">
                        {e.value ? "Yes" : "No"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
