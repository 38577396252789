import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";

import api from "../../../services/api";


export default ({ campaign }) => {
  const [values, setValues] = useState(campaign);

  const history = useHistory();

  const onUpdate = async () => {
    const { data } = await api.put(`/campaign/${campaign._id}`, values);
    toast.success("Updated!");
  };

  const onDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/campaign/${campaign._id}`);
    toast.success("Delete!");
    history.push("/campaign");
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Name</div>
          <input
            className="input"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
        </div>
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <button className="btn btn-primary" onClick={onUpdate}>
          Update
        </button>
        <button className="btn btn-red" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};
