import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useHistory, Link } from "react-router-dom";

import Loader from "../../components/loader";
import FileInput from "../../components/FileInput";

import api from "../../services/api";

import { BUSINESS_SECTOR, CURRENCY, NACE_CODE } from "../../constants";
import MultiSelect from "../../components/MultiSelect";

const INFORMATION = "INFORMATION";
const RAW_DATA = "RAW_DATA";

export default () => {
  const [company, setCompany] = useState();
  const [tab, setTab] = useState(INFORMATION);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/company/${id}`);
        setCompany(data);
      } catch (e) {
        toast.error(e.message);
      }
    })();
  }, [id]);

  if (!company) return <Loader />;

  return (
    <div className="grid md:grid-cols-12 w-full gap-x-4 gap-y-2">
      <div className="md:col-span-8">
        <nav className="flex items-center gap-2 pl-2">
          <button className={`${tab === INFORMATION && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(INFORMATION)}>
            Information
          </button>
          <button className={`${tab === RAW_DATA && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(RAW_DATA)}>
            Raw Data
          </button>
        </nav>
        <div className="bg-white rounded-md p-6 border border-primary-black-50">
          {tab === INFORMATION && <Information company={company} />}
          {tab === RAW_DATA && <RawData company={company} />}
        </div>
      </div>

      <div className="md:col-span-4 md:space-y-10 space-y-4 row-start-1 md:row-start-auto">
        <div className="bg-white rounded-lg border mb-2 divide-y">
          <div className="py-4 px-5">
            <Link className="text-sm text-app underline" to={`/questionnaire?company_id=${company._id}&role=user`}>
              See questionnaires
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Information = ({ company }) => {
  const [values, setValues] = useState(company);
  const [selectedNaceCodes, setSelectedNaceCodes] = useState([]);
  const initialSelectedNaceCodesRef = useRef(true);

  const history = useHistory();

  const onUpdate = async () => {
    const { data } = await api.put(`/company/${company._id}`, values);
    toast.success("Updated!");
  };

  const onDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/company/${company._id}`);
    toast.success("Delete!");
    history.push("/company");
  };

  useEffect(() => {
    if (initialSelectedNaceCodesRef.current) {
      initialSelectedNaceCodesRef.current = false;
      if (Array.isArray(values.nace_codes)) {
        const tempSelectedNaceCodes = values.nace_codes.map((codeString) => {
          const [code, name] = codeString.split(" - ");
          const naceCode = NACE_CODE.find((item) => item.code === code && item.name === name);
          return naceCode ? naceCode : null;
        });
        setSelectedNaceCodes(tempSelectedNaceCodes);
      }
    }
  }, [values.nace_codes]);

  useEffect(() => {
    if (Array.isArray(selectedNaceCodes)) {
      const tempNaceCodes = selectedNaceCodes.map((naceCode) => `${naceCode.code} - ${naceCode.name}`);
      setValues({ ...values, nace_codes: tempNaceCodes });
    }
  }, [selectedNaceCodes]);

  const handleSelectedNaceCode = (e) => {
    const selectedNaceCodes = [];
    e.forEach((codeString) => {
      const section = codeString.value?.substring(0, 1);
      if (codeString.value?.length > 2) {
        const code = codeString.value?.substring(1);
        const naceCode = NACE_CODE.find((item) => item.section === section && item.code === code);

        if (naceCode) {
          selectedNaceCodes.push(naceCode);
        }
      } else {
        const naceCode = NACE_CODE.find((item) => item.section === section && item.code === "");

        if (naceCode) {
          selectedNaceCodes.push(naceCode);
        }
      }
    });
    setSelectedNaceCodes(selectedNaceCodes);
  };

  return (
    <div>
      <div className="mb-6">
        <FileInput
          value={values.logo}
          onChange={async (e) => {
            const { data } = await api.put(`/company/${company._id}`, {
              logo: e.target.value[0],
            });
            setValues(data);
            toast.success("Updated!");
          }}
          folder={`/company`}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Company Name</div>
          <input className="input" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Incorporation Date</div>
          <input
            className="input"
            type="date"
            value={values?.incorporation_at && new Date(values?.incorporation_at).toISOString().split("T")[0]}
            onChange={(e) => setValues({ ...values, incorporation_at: e.target.value })}
            name="vintage_at"
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Investment Date</div>
          <input
            className="input"
            type="date"
            value={values?.investment_at && new Date(values?.investment_at).toISOString().split("T")[0]}
            onChange={(e) => setValues({ ...values, investment_at: e.target.value })}
            name="vintage_at"
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Country</div>
          <input className="input" value={values.country} onChange={(e) => setValues({ ...values, country: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Address</div>
          <input className="input" value={values.address} onChange={(e) => setValues({ ...values, address: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">City</div>
          <input className="input" value={values.city} onChange={(e) => setValues({ ...values, city: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Zip code</div>
          <input className="input" value={values.zipcode} onChange={(e) => setValues({ ...values, zipcode: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Turnover</div>
          <input type="number" className="input" value={values.turnover} onChange={(e) => setValues({ ...values, turnover: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Currency</div>
          <Select value={values.currency} onChange={(currency) => setValues({ ...values, currency })} data={CURRENCY.map(({ code, name }) => `${code} - ${name}`)} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Total employees</div>
          <input type="number" className="input" value={values.employees} onChange={(e) => setValues({ ...values, employees: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Business sector and activity</div>
          <input className="input" value={values.sector} onChange={(e) => setValues({ ...values, sector: e.target.value })} />
        </div>

        <div className="w-full">
          <div className="text-sm font-medium mb-2">Business sector and activity</div>
          <Select value={values.sector} onChange={(sector) => setValues({ ...values, sector })} data={BUSINESS_SECTOR} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">SIREN</div>
          <input className="input" value={values.SIREN} onChange={(e) => setValues({ ...values, SIREN: e.target.value })} />
        </div>
        <div className="w-full col-span-2">
          <div className="text-sm font-medium mb-2">Nace Code</div>
          <MultiSelect
            width="w-full"
            naceCode={true}
            values={selectedNaceCodes?.map((naceCode) => ({ value: `${naceCode.section}${naceCode.code}` }))}
            onSelectedChange={handleSelectedNaceCode}
            placeholder="Select..."
            options={NACE_CODE.map((nc) => ({ value: `${nc.section}${nc.code}`, label: `${nc.code} ${nc.name}` }))}
            renderOption={(item) => <div>{item}</div>}
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Website (Optional)</div>
          <input className="input" value={values.website} onChange={(e) => setValues({ ...values, website: e.target.value })} />
        </div>
      </div>

      <div className="flex items-center space-x-2 mt-4">
        <button className="btn btn-primary" onClick={onUpdate}>
          Update
        </button>
        <button className="btn btn-red" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

const Select = ({ value, onChange, data }) => {
  return (
    <select className="select input" value={value} onChange={(e) => onChange(e.target.value)}>
      <option value={""}>Select...</option>
      {data.map((item, i) => (
        <option value={item} key={i}>
          {item}
        </option>
      ))}
    </select>
  );
};

const RawData = ({ company }) => {
  return (
    <div className="">
      <pre> {JSON.stringify(company, null, 2)}</pre>
    </div>
  );
};
