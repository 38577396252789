import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";

import Loader from "../../components/loader";

import SelectCompany from "../../components/SelectCompany";
import Modal from "../../components/modal";

import api from "../../services/api";
import { APP_URL } from "../../config";

const INFORMATION = "INFORMATION";
const RAW_DATA = "RAW_DATA";

const View = () => {
  const [user, setUser] = useState();
  const [tab, setTab] = useState(INFORMATION);

  const { id } = useParams();

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    try {
      const { data } = await api.get(`/user/${id}`);
      setUser(data);
    } catch (e) {
      toast.error(e.message);
    }
  }

  if (!user) return <Loader />;

  return (
    <>
      <div className="grid md:grid-cols-12 w-full gap-x-4 gap-y-2">
        <div className="md:col-span-10">
          <nav className="flex items-center gap-2 pl-2">
            <button className={`${tab === INFORMATION && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(INFORMATION)}>
              Information
            </button>
            <button className={`${tab === RAW_DATA && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(RAW_DATA)}>
              Raw Data
            </button>
          </nav>
          <div className="bg-white rounded-md p-6 border border-primary-black-50">
            {tab === INFORMATION && <Information user={user} />}
            {tab === RAW_DATA && <RawData user={user} />}
          </div>
        </div>
        <div className="md:col-span-2 md:space-y-10 space-y-4 self-center row-start-1 md:row-start-auto">
          <a
            href={`${APP_URL}/auth/loginas?id=${user._id}&token=${api.getToken()}`}
            target="_blank"
            className="py-3 px-6 border border-app text-sm text-app font-bold rounded-lg md:w-auto w-full">
            Log in as
          </a>
        </div>
      </div>
      <Emails email={user.email} />
    </>
  );
};

const Information = ({ user }) => {
  const [values, setValues] = useState(user);

  const history = useHistory();

  const onUpdate = async () => {
    const { data } = await api.put(`/user/${user._id}`, values);
    toast.success("Updated!");
  };

  const onDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/user/${user._id}`);
    toast.success("Delete!");
    history.push("/user");
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full">
          <div className="text-sm font-medium mb-2">First and last name</div>
          <input className="input" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Role</div>

          <select
            onChange={(e) => setValues({ ...values, role: e.target.value })}
            value={values.role}
            className="select bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] cursor-pointer shadow-sm">
            <option disabled>Role</option>
            <option value="user">User</option>
            <option value="partner">Partner</option>
          </select>
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Email</div>
          <input className="input" value={values.email} onChange={(e) => setValues({ ...values, emails: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Job Title</div>
          <input className="input" value={values.job_title} onChange={(e) => setValues({ ...values, job_title: e.target.value })} />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Phone</div>
          <input className="input" value={values.phone} onChange={(e) => setValues({ ...values, phone: e.target.value })} />
        </div>
        {user.role === "user" && (
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Company</div>
            <SelectCompany
              value={values.company_id}
              onChange={(company) => {
                if (!values.companies.some((c) => c.id === company._id)) {
                  const updatedCompanies = [...values.companies, { name: company.name, id: company._id }];
                  setValues({ ...values, companies: updatedCompanies });
                }
              }}
              fund_id={values.fund_id}
            />
          </div>
        )}

        <div className="w-full">
          <div className="text-sm font-medium mb-2">Language</div>
          <select className="select input" value={values.language} onChange={(e) => setValues({ ...values, language: e.target.value })}>
            <option value="en">English</option>
          </select>
        </div>
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <button className="btn btn-primary" onClick={onUpdate}>
          Update
        </button>
        <button className="btn btn-red" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

const RawData = ({ user }) => {
  return (
    <div className="">
      <pre> {JSON.stringify(user, null, 2)}</pre>
    </div>
  );
};

const Emails = ({ email }) => {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`/user/emails/${encodeURIComponent(email)}`);
      setEmails(data);
    }
    fetchData();
  }, [email]);

  return (
    <div className="pt-5">
      <div className="textlg font-bold mb-4">Emails</div>
      <table className="min-w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden ">
        <thead className="bg-[#EFF2FE] text-left text-primary-black-100">
          <tr>
            <th className="p-3">Subject</th>
            <th className="p-3 w-40">Events</th>
            <th className="p-3">Date</th>
            <th className="p-3">Tag</th>
            <th className="p-3">Message</th>
            <th className="p-3">Reason</th>
            <th className="p-3">Action</th>
          </tr>
        </thead>
        <tbody>
          {emails.map((e) => {
            return <Hit hit={e} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

const Hit = ({ hit }) => {
  const [content, setContent] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/user/email-content/${hit.uuid}`);
      if (data) return setContent(data);
      toast.error("No content found");
    })();
  }, []);

  function renderEvents() {
    return (content?.events || []).map((e) => (
      <div className="py-1">
        <div className="font-medium mb-1">{e?.name}</div>
        <div className="text-xs">{new Date(e?.time).toLocaleString()}</div>
      </div>
    ));
  }

  return (
    <tr className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
      <td className="p-3">{hit.subject}</td>
      <td className="p-3">{renderEvents()}</td>
      <td className="p-3">{hit.date && `${hit.date.slice(0, 10)} à ${hit.date.slice(11, 19)}`}</td>
      <td className="p-3">{hit?.tags?.join(",")}</td>
      <td className="p-3">{hit.messageId}</td>
      <td className="p-3">{hit.reason}</td>
      <td className="p-3 underline cursor-pointer" onClick={async () => {}}>
        <ViewEmail content={content} />
      </td>
    </tr>
  );
};

const ViewEmail = ({ content }) => {
  const [open, setOpen] = useState(false);

  if (!content) return null;
  return (
    <>
      <div onClick={() => setOpen(true)} className="underline text-sm">
        View Message
      </div>
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="text-lg font-bold mb-5">{content.subject}</div>
        <div style={{ height: 800 }}>
          <iframe srcDoc={content.body} height="100%" width="100%" />
        </div>
      </Modal>
    </>
  );
};

export default View;
