import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";

import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import Modal from "../../../components/modal";
import SearchBar from "../../../components/searchBar";
import Pagination from "../../../components/pagination";
import { dimensions } from "../../../constants";
import { question_types } from "../../../constants";
import api from "../../../services/api";

import View from "../../../components/website_dataset_section";
import Autocomplete from "../../../components/Autocomplete";

export default ({ dataset }) => {
  const [datasetSections, setDatasetSections] = useState();
  const [filter, setFilter] = useState({ page: 1, per_page: 500 });
  const [total, setTotal] = useState(0);
  const [view, setView] = useState();

  const currentPage = filter.page;

  useEffect(() => {
    get();
  }, [filter]);

  async function get() {
    const nf = { ...filter, ressource_id: dataset._id };
    const { data, total } = await api.post("/ressource_section/search", nf);
    setDatasetSections(data);
    setTotal(total);
  }

  if (!datasetSections) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-bold w-full">{dataset.title?.en} </h3>

        <div className="flex gap-6 w-full justify-end">
          <SearchBar search={filter.search} setFilter={setFilter} />
          <Create dataset={dataset} />
        </div>
      </div>

      <View datasetId={dataset._id} value={view} onChange={(e) => setView(null)} />
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden table-auto">
                <thead className="bg-[#EFF2FE]">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Title
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Category
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Sub Category
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {datasetSections.map((e) => {
                    return (
                      <tr
                        key={e._id}
                        className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`}
                        onClick={() => {
                          console.log(e);
                          setView(e);
                        }}>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.title?.en}</td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                          <p className={`text-center px-1 py-2 rounded font-medium ${dimensions.find((d) => d.name.en === e.dimension?.en)?.color || "bg-gray-300"}`}>
                            {e.category?.en || "-"}
                          </p>
                        </td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.sub_category?.en}</td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{(e.created_at || "")?.slice(0, 10)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="flex justify-between flex-row p-3 text-primary-black-90 border-r border-primary-black-50 bg-[#EFF2FE] rounded-b-lg">
                <div className="justify-center">{` ${(currentPage - 1) * filter.per_page + 1} à ${Math.min(currentPage * filter.per_page, total)} sur ${total} résultat(s)`}</div>
                <Pagination
                  per_page={filter.per_page}
                  total={total}
                  onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
                  onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
                  currentPage={filter.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = (dataset) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    ressource_id: dataset.dataset._id,
    title: { en: "" },
    sub_category: { en: "" },
  });

  async function onCreate() {
    const { ok } = await api.post("/ressource_section", values);
    if (!ok) return toast.error("Error!");
    toast.success("Created!");
    window.location.reload();
    setOpen(false);
  }

  async function fetchSubCategories(SubCategoryFilter) {
    try {
      const nf = {
        sub_category: SubCategoryFilter,
        ressource_id: dataset.dataset._id,
      };
      const { data } = await api.post("/ressource_section/search/subCategory", nf);
      return data.map((d) => d.en);
    } catch (error) {
      console.error("Erreur lors de la récupération des sous catégorie:", error);
      return [];
    }
  }

  useEffect(() => {
    console.log(values.sub_category?.en);
  }, [values]);

  const handleUpdateSubCategory = async (e) => {
    setValues({ ...values, sub_category: { en: e } });
  };

  return (
    <div>
      <button className="btn btn-primary whitespace-nowrap" onClick={() => setOpen(true)}>
        Create dataset section
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6 overflow-y-visible" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Title</div>
          <input className="input block" value={values.title?.en} onChange={(e) => setValues({ ...values, title: { en: e.target.value } })} />
        </div>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Sub Category</div>
          <Autocomplete fetchData={fetchSubCategories} handleUpdate={handleUpdateSubCategory} value={values.sub_category?.en} placeholderText={"Indicate the sub category"} />
        </div>

        <button className="btn btn-primary disabled:opacity-80 disabled:cursor-not-allowed" disabled={!(values.title?.en && values.sub_category?.en)} onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
