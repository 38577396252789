import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";
import Pagination from "../../components/pagination";
import SelectFund from "../../components/SelectFund";
import MultiSelect from "../../components/MultiSelect";

import api from "../../services/api";

export default () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const [companies, setCompanies] = useState();
  const [funds, setFunds] = useState();
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 20,
    funds: urlParams.get("fund_id") ? [urlParams.get("fund_id")] : [],
  });

  async function getFunds() {
    const { data, ok } = await api.post("/fund/search");
    if (!ok) return toast.error("Error!");
    setFunds(data);
  }

  useEffect(() => {
    getFunds();
  }, []);

  useEffect(() => {
    (async () => {
      const { data, total } = await api.post("/company/search", {
        search: filter.search,
        page: filter.page,
        per_page: filter.per_page,
        funds: filter.funds,
      });
      setCompanies(data);
      setTotal(total);
    })();
  }, [filter]);

  if (!companies || !funds) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex gap-6 w-full justify-end">
          <MultiSelect
            values={filter.funds?.map((fundId) => ({ value: fundId }))}
            onSearch={(search) => setFilter({ ...filter, search })}
            onSelectedChange={(e) => {
              const ids = e.map((e) => e.value);
              setFilter({ ...filter, funds: ids });
            }}
            placeholder="Funds"
            options={funds.map((f) => ({ value: f._id, label: f.name }))}
            renderOption={(item) => <div>{item}</div>}
          />
          <SearchBar search={filter.search} setFilter={setFilter} />
          <Create />
        </div>
      </div>

      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden ">
                <thead className="bg-[#EFF2FE]">
                  <tr>
                    <th
                      scope="col"
                      className="p-3 text-left text-primary-black-100 "
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-primary-black-100 "
                    >
                      Fund
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-primary-black-100 "
                    >
                      Country
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-primary-black-100 "
                    >
                      Currency
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-primary-black-100 "
                    >
                      Sector
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left text-primary-black-100 "
                    >
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {companies.map((e) => (
                    <tr
                      key={e.email}
                      className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`}
                      onClick={() => history.push(`/company/${e._id}`)}
                    >
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {e.name}
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {e.fund_name}
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {e.country}
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {e.currency}
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {e.sector}
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {(e.created_at || "")?.slice(0, 10)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-between items-center w-full p-3 text-primary-black-90 border-r border-primary-black-50 bg-[#EFF2FE] rounded-b-lg">
                <div>{`${(filter.page - 1) * filter.per_page + 1} à ${Math.min(
                  filter.page * filter.per_page,
                  total
                )} sur ${total} résultat(s)`}</div>
                <Pagination
                  per_page={filter.per_page}
                  total={total}
                  onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
                  onPrevious={() =>
                    setFilter((f) => ({ ...f, page: +f.page - 1 }))
                  }
                  currentPage={filter.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const history = useHistory();

  async function onCreate() {
    const res = await api.post("/company", values);
    toast.success("Created!");
    history.push(`/company/${res.data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Create Company
      </button>

      <Modal
        isOpen={open}
        className="max-w-xl w-full p-6"
        onClose={() => setOpen(false)}
      >
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Name</div>
            <input
              className="input block"
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
          </div>
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Fund</div>
            <SelectFund
              value={values.fund_name}
              onChange={(fund) => {
                setValues({
                  ...values,
                  fund_id: fund._id,
                  fund_name: fund.name,
                });
              }}
            />
          </div>
        </div>

        <button
          className="btn btn-primary mt-4 disabled:opacity-80 disabled:cursor-not-allowed"
          disabled={!values.name || !values.fund_id || !values.fund_name}
          onClick={onCreate}
        >
          Create
        </button>
      </Modal>
    </div>
  );
};
