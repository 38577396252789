import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";
import Pagination from "../../components/pagination";
import SelectFund from "../../components/SelectFund";
import SelectCompany from "../../components/SelectCompany";
import SelectQuestionnaire from "../../components/SelectQuestionnaire";
import MultiSelect from "../../components/MultiSelect";

import api from "../../services/api";

const List = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const [users, setUsers] = useState();
  const [funds, setFunds] = useState();
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 50,
    funds: urlParams.get("fund_id") ? [urlParams.get("fund_id")] : [],
    roles: urlParams.get("role") ? [urlParams.get("role")] : [],
  });

  async function getFunds() {
    const { data, ok } = await api.post("/fund/search");
    if (!ok) return toast.error("Error!");
    setFunds(data);
  }

  useEffect(() => {
    getFunds();
  }, []);

  useEffect(() => {
    (async () => {
      const { data, total } = await api.post("/user/search", {
        roles: filter.roles,
        funds: filter.funds,
        search: filter.search,
        page: filter.page,
        per_page: filter.per_page,
      });
      setUsers(data);
      setTotal(total);
    })();
  }, [filter]);

  if (!users || !funds) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex gap-6 w-full justify-end">
          <MultiSelect
            values={filter.roles?.map((role) => ({ value: role }))}
            onSearch={(search) => setFilter({ ...filter, search })}
            onSelectedChange={(selectedOptions) => {
              const selectedRoles = selectedOptions.map((option) => option.value);
              setFilter({ ...filter, roles: selectedRoles });
            }}
            placeholder="Role"
            options={[
              { value: "user", label: "User" },
              { value: "partner", label: "Partner" },
            ]}
            renderOption={(item) => <div>{item}</div>}
          />
          <MultiSelect
            values={filter.funds?.map((fundId) => ({ value: fundId }))}
            onSearch={(search) => setFilter({ ...filter, search })}
            onSelectedChange={(e) => {
              const ids = e.map((e) => e.value);
              setFilter({ ...filter, funds: ids });
            }}
            placeholder="Funds"
            options={funds.map((f) => ({ value: f._id, label: f.name }))}
            renderOption={(item) => <div>{item}</div>}
          />
          <SearchBar search={filter.search} setFilter={setFilter} />
          <Create />
        </div>
      </div>

      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden ">
                <thead className="bg-[#EFF2FE]">
                  <tr>
                    <th className="p-3 text-left text-primary-black-100 ">Name</th>
                    <th className="p-3 text-left text-primary-black-100 ">Email</th>
                    <th className="p-3 text-left text-primary-black-100 ">Fund</th>
                    <th className="p-3 text-left text-primary-black-100">Role</th>
                    <th className="p-3 text-left text-primary-black-100">Last login</th>
                    <th className="p-3 text-left text-primary-black-100">Created at</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {users.map((e) => (
                    <tr key={e.email} className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`} onClick={() => history.push(`/user/${e._id}`)}>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.name}</td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.email}</td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.fund_name}</td>

                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.role}</td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{(e.last_login_at || "")?.slice(0, 10)}</td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{(e.created_at || "")?.slice(0, 10)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-between items-center w-full p-3 text-primary-black-90 border-r border-primary-black-50 bg-[#EFF2FE] rounded-b-lg">
                <div>{`${(filter.page - 1) * filter.per_page + 1} à ${Math.min(filter.page * filter.per_page, total)} sur ${total} résultat(s)`}</div>
                <Pagination
                  per_page={filter.per_page}
                  total={total}
                  onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
                  onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
                  currentPage={filter.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ companies: [], role: "user" });
  const history = useHistory();
  const [company, setCompany] = useState();
  const [questionnaire, setQuestionnaire] = useState();

  useEffect(() => {
    setCompany(undefined);
    setQuestionnaire(undefined);
    setValues((prevValues) => ({
      ...prevValues,
      questionnaire_name: "",
      questionnaire_id: "",
      companies: [],
    }));
  }, [values.role]);

  async function onCreate() {
    if (values.role === "user") values.companies = [{ id: company._id, name: company.name }];
    const { data, ok } = await api.post("/user/invite", values);
    if (questionnaire) {
      const users = [...questionnaire.users, data._id];
      await api.put(`/questionnaire/${questionnaire._id}`, { users });
    }
    if (!ok) return toast.error("Error!");
    toast.success("Created!");
    history.push(`/user/${data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Create user
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Name</div>
            <input className="input block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </div>
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Email</div>
            <input className="input block" type="email" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
          </div>
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Role</div>

            <select
              onChange={(e) => setValues({ ...values, role: e.target.value })}
              value={values.role}
              className="select bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] cursor-pointer shadow-sm">
              <option disabled>Role</option>
              <option value="user">User</option>
              <option value="partner">Partner</option>
            </select>
          </div>
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Fund</div>
            <SelectFund
              value={values.fund_name}
              onChange={(fund) => {
                setValues({
                  ...values,
                  fund_id: fund._id,
                  fund_name: fund.name,
                });
              }}
            />
          </div>

          {values.role === "user" && (
            <>
              {" "}
              <div className="w-full">
                <div className="text-sm font-medium mb-2">Company</div>
                <SelectCompany
                  value={values.companies[0]?.name}
                  fund_id={values.fund_id}
                  onChange={(company) => {
                    setCompany(company);
                  }}
                />
              </div>
              <div className="w-full">
                <div className="text-sm font-medium mb-2">Questionnaire</div>
                <SelectQuestionnaire
                  value={questionnaire?.name}
                  onChange={(selectedQuestionnaire) => {
                    setQuestionnaire(selectedQuestionnaire);
                    setValues((prevValues) => ({
                      ...prevValues,
                      questionnaire_name: selectedQuestionnaire.name,
                      questionnaire_id: selectedQuestionnaire._id,
                    }));
                  }}
                  company_id={company?._id}
                />
              </div>
            </>
          )}
        </div>

        <button
          className="btn btn-primary mt-4 disabled:opacity-80 disabled:cursor-not-allowed"
          disabled={
            (values.role === "user" && (!values.questionnaire_id || !company)) || !values.name || !values.email || !values.fund_id
            // !values.questionnaire_id ||
            // !company
          }
          onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};

export default List;
