import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";

import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import Pagination from "../../../components/pagination";
import SearchBar from "../../../components/searchBar";

import api from "../../../services/api";
import { dimensions, question_types } from "../../../constants";

const Template = ({ dataset }) => {
  const [indicatorSelected, setIndicatorSelected] = useState();
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [indicators, setIndicators] = useState();
  const [filter, setFilter] = useState({ page: 1, per_page: 500 });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    (async () => {
      filter.dataset_id = dataset.master_dataset_id;
      const { data, total } = await api.post("/indicator/search", filter);
      setIndicators(data);
      setTotal(total);
    })();
  }, [filter, dataset]);

  const onSelectAll = () => {
    setSelectAll((state) => !state);
    if (!selectAll) {
      const newArr = [...selected];
      for (let i = 0; i < indicators.length; i++) {
        if (!newArr.find((e) => e._id === indicators[i]._id)) {
          newArr.push(indicators[i]);
        }
      }
      setSelected(newArr);
    } else {
      setSelected([]);
    }
  };

  function onSelect(v) {
    if (selectAll) setSelectAll(false);
    let arr = [...selected];
    if (arr.find((item) => item._id === v._id)) arr = arr.filter((item) => item._id !== v._id);
    else arr = [...selected, v];
    setSelected(arr);
  }

  const handleAddMultiple = async () => {
    setLoading(true);
    for (let i = 0; i < selected.length; i++) {
      try {
        const indicator = selected[i];
        await addIndicator(indicator);
      } catch (e) {
        console.log(e);
      }
    }
    toast.success("Done!");
    setSelected([]);
  };

  const addIndicator = async (obj) => {
    obj.dataset_id = dataset._id;
    obj.dataset_name = dataset.name;
    obj.master_indicator_id = obj._id;
    delete obj._id;
    delete obj.__v;
    delete obj.created_at;

    const { data } = await api.post(`/indicator`, obj);
  };

  return (
    <section>
      <div className="flex justify-between">
        <SearchBar search={filter.search} setFilter={setFilter} />
        <button
          disabled={selected.length === 0}
          className="bg-blue-400 border-blue-400 btn btn-primary whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={handleAddMultiple}>
          + Add to questionnaire
        </button>
      </div>

      <div className="grid grid-cols-4 gap-4 items-center py-5">
        <div className="flex items-center justify-end gap-x-2.5">
          <div className="w-full relative"></div>
        </div>
      </div>
      <div className="border rounded-xl overflow-hidden">
        {!indicators ? (
          <Loader />
        ) : (
          <div className="overflow-hidden ring-1 ring-primary-black-50">
            <table className="min-w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden table-auto">
              <thead className="bg-[#EFF2FE]">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100" onClick={onSelectAll}>
                    <div className="flex items-center justify-center">
                      <label className={`cursor-pointer w-5 h-5 flex items-center justify-center rounded border mr-1 border-gray-300 ${selectAll && "bg-primary"}`}>
                        <AiOutlineCheck className="text-white text-xs" />
                      </label>
                      <p>Key</p>
                    </div>
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                    Dimension
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                    Sub Dimension
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                    Indicator
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                    Question type
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                    Regulation
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                    Flag rule
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                    NACE code
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-primary-black-50 bg-white">
                {indicators.map((e) => {
                  const checked = selected.some((s) => s._id === e._id);
                  return (
                    <tr
                      key={e._id}
                      className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`}
                      //   onClick={() => setView(e)}
                    >
                      <td
                        className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100"
                        onClick={(event) => {
                          event.stopPropagation();
                          onSelect(e);
                        }}>
                        <div className="flex items-center justify-center">
                          <label className={`cursor-pointer w-5 h-5 flex items-center justify-center rounded border mr-1 border-gray-300 ${checked && "bg-primary"}`}>
                            <AiOutlineCheck className="text-white text-xs" />
                          </label>
                          <p>{e.key}</p>
                        </div>
                      </td>

                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        <p className={`text-center px-1 py-2 rounded font-medium ${dimensions.find((d) => d.name === e.dimension)?.color || "bg-gray-300"}`}>{e.dimension}</p>
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.sub_dimension}</td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50 max-w-md">{e.question?.en}</td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        <div className="flex justify-start items-center">
                          <p
                            className={`w-max text-center px-4 py-2 rounded text-app-gray font-semibold ${
                              question_types.find((q_type) => q_type.name === e.question_type)?.color || "bg-gray-300"
                            }`}>
                            {e.question_type}
                          </p>
                        </div>
                      </td>
                      <td className="p-3 text-sm text-center text-primary-black-90 border-r border-primary-black-50">
                        <p>{e.regulation ? e.regulation : "-"}</p>
                      </td>
                      <td className="p-3 text-sm text-center text-primary-black-90 border-r border-primary-black-50">
                        <p>{e.flag_rule ? e.flag_rule : "-"}</p>
                      </td>
                      <td className="p-3 text-sm text-center text-primary-black-90 border-r border-primary-black-50">
                        <p>{e.nace_code ? e.nace_code : "-"}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex justify-between flex-row p-3 text-primary-black-90 border-r border-primary-black-50 bg-[#EFF2FE] rounded-b-lg">
              <div className="justify-center">{` ${(filter.page - 1) * filter.per_page + 1} à ${Math.min(filter.page * filter.per_page, total)} sur ${total} résultat(s)`}</div>
              <Pagination
                per_page={filter.per_page}
                total={total}
                onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
                onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
                currentPage={filter.page}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Template;
