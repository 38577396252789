import React from "react";
import { Route, Switch } from "react-router-dom";

import List from "./List";
import View from "./View";

const User = () => {
  return (
    <Switch>
      <Route path="/user/:id" component={View} />
      <Route path="/user" component={List} />
    </Switch>
  );
};

export default User;
