import React, { useState } from "react";

import BooleanIndicator from "./components/BooleanIndicator";
import TextIndicator from "./components/TextIndicator";
import PieIndicator from "./components/PieIndicator";
import toast from "react-hot-toast";
import MultiChoicesIndicator from "./components/MultiChoicesIndicator";

import api from "../../../services/api";

const QUESTION_TYPE = {
  BOOLEAN: "boolean",
  TEXT: "text",
  MULTIPLE_CHOICE: "multiple_choice",
  PERCENTAGE: "percentage",
};

export default ({ indicator }) => {
  const [values, setValues] = useState(indicator);
  const [language, setLanguage] = useState("en");

  const onUpdate = async () => {
    const { data, ok } = await api.put(`/indicator/${indicator._id}`, values);
    if (!ok) return toast.error(data);
    setValues(data);
    toast.success("Updated!");
  };

  console.log("values", values);
  return (
    <div>
      <h1 className="text-2xl mb-10">Report</h1>

      <div className="grid grid-cols-2 gap-4">
        <div className="w-full">
          <div className="text-sm font-medium mb-2">Indicator</div>
          <textarea
            className="input"
            value={values.indicator && values.indicator[language]}
            onChange={(e) =>
              setValues({
                ...values,
                indicator: {
                  ...values.indicator,
                  [language]: e.target.value,
                },
              })
            }
            onBlur={onUpdate}
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">indicator Final Report</div>
          <textarea
            className="input"
            value={values.indicator_final_report && values.indicator_final_report[language]}
            onChange={(e) =>
              setValues({
                ...values,
                indicator_final_report: {
                  ...values.indicator_final_report,
                  [language]: e.target.value,
                },
              })
            }
            onBlur={onUpdate}
          />
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-2">indicator Final Report Description</div>
          <textarea
            className="input"
            value={values.indicator_final_report_description && values.indicator_final_report_description[language]}
            onChange={(e) =>
              setValues({
                ...values,
                indicator_final_report_description: {
                  ...values.indicator_final_report_description,
                  [language]: e.target.value,
                },
              })
            }
            onBlur={onUpdate}
          />
        </div>

        <div className="col-span-2">
          <h1 className="text-2xl mb-10">Report Indicator</h1>
          <IndicatorForm indicator={indicator} values={values} setValues={setValues} />
        </div>
        <div className="w-full col-span-2">
          <div>
            <button onClick={onUpdate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const IndicatorForm = ({ indicator, values, setValues }) => {
  switch (indicator.question_type) {
    case QUESTION_TYPE.BOOLEAN:
      return <BooleanIndicatorForm values={values} setValues={setValues} />;
    case QUESTION_TYPE.TEXT:
      return <TextIndicatorForm values={values} setValues={setValues} />;
    case QUESTION_TYPE.MULTIPLE_CHOICE:
      return <MultiChoicesIndicatorForm indicator={indicator} values={values} setValues={setValues} />;
    case QUESTION_TYPE.PERCENTAGE:
      return <PieIndicatorForm values={values} setValues={setValues} />;
    default:
      // Si le type de question n'est pas pris en charge, afficher un message d'erreur ou un composant par défaut
      return <TitleForm values={values} setValues={setValues} />;
  }
};

const BooleanIndicatorForm = ({ values, setValues }) => {
  const [valueSelected, setValueSelected] = useState("Yes");
  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <select onChange={(e) => setValueSelected(e.target.value)} value={valueSelected}>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <input type="text" value={values.graph_title} onChange={(e) => setValues({ ...values, graph_title: e.target.value })} placeholder="title" />
        <input type="text" value={values.graph_text_true} onChange={(e) => setValues({ ...values, graph_text_true: e.target.value })} placeholder="texttrue" />
        <input type="text" value={values.graph_text_false} onChange={(e) => setValues({ ...values, graph_text_false: e.target.value })} placeholder="textfalse" />
      </div>
      <BooleanIndicator value={valueSelected} title={values.graph_title} textTrue={values.graph_text_true} textFalse={values.graph_text_false} />
    </div>
  );
};

const TextIndicatorForm = ({ values, setValues }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <input type="text" value={values.graph_title} onChange={(e) => setValues({ ...values, graph_title: e.target.value })} placeholder="title" />
      </div>
      <TextIndicator value={"valeur inscrite par l'utilisateur"} title={values.graph_title} />
    </div>
  );
};

const MultiChoicesIndicatorForm = ({ indicator, values, setValues }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex flex-col gap-2">
        <input type="text" value={values.graph_title} onChange={(e) => setValues({ ...values, graph_title: e.target.value })} placeholder="title" />
      </div>
      <MultiChoicesIndicator
        options={indicator.question_choices.map((choice) => choice.en)}
        selected={indicator.question_choices.map((choice) => choice.en)[0]}
        classNameSelected="text-white bg-gray-500 border-gray-500"
        classNameNotSelected="text-gray-600 bg-gray-200 border-gray-300"
        title={values.graph_title}
      />
    </div>
  );
};

const PieIndicatorForm = ({ values, setValues }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <input type="text" value={values.graph_title} onChange={(e) => setValues({ ...values, graph_title: e.target.value })} placeholder="title" />
        <input type="text" value={values.graph_text_value} onChange={(e) => setValues({ ...values, graph_text_value: e.target.value })} placeholder="text value" />
        <input
          type="text"
          value={values.graph_text_reverse_value}
          onChange={(e) => setValues({ ...values, graph_text_reverse_value: e.target.value })}
          placeholder="text reverse value"
        />
      </div>
      <PieIndicator
        colors={["#b2b5b9", "#6b7280", "#828489", "#8a8d92", "#b4b7bd"]}
        title={values.graph_title}
        values={[
          {
            label: values.graph_text_value,
            value: 50,
          },
          {
            label: values.graph_text_reverse_value,
            value: 100 - 50,
          },
        ]}
      />
    </div>
  );
};

const TitleForm = ({ values, setValues }) => {
  return (
    <div>
      <input type="text" value={values.graph_title} onChange={(e) => setValues({ ...values, graph_title: e.target.value })} placeholder="title" />
    </div>
  );
};
