import React from "react";
import { Route, Switch } from "react-router-dom";

import List from "./list";
import View from "./view";

export default function Index() {
  return (
    <Switch>
      <Route path="/partner/:id" component={View} />
      <Route path="/partner" component={List} />
    </Switch>
  );
}
