import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

import App from "./App.jsx";
import store from "./redux/store";
import "./index.css";
import { ENVIRONMENT, SENTRY_DSN } from "./config";

if (ENVIRONMENT !== "development") {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.replayIntegration()],
    environment: "app",
    tracesSampleRate: 0.1,
  });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <App />,
    <Toaster />
    {/* <ReduxToastr timeOut={1500} transitionIn="fadeIn" transitionOut="fadeOut" /> */}
  </Provider>,
);
