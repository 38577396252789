import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Information from "./information";
import DatasetSections from "./dataset_sections";

import api from "../../../services/api";

const INFORMATION = "INFORMATION";
const DATASETSECTIONS = "DATASETSECTIONS";
const RAW_DATA = "RAW_DATA";

export default ({}) => {
  const [dataset, setDataset] = useState(null);
  const [tab, setTab] = useState(DATASETSECTIONS);

  const { id } = useParams();

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const { data } = await api.get(`/ressource/${id}`);
    setDataset(data);
  }

  if (!dataset) return <div />;

  return (
    <div>
      <nav className="flex items-center gap-2 pl-2">
        <button
          className={`${
            tab === DATASETSECTIONS && "bg-black-80 text-white"
          } transition-colors px-3 py-2 text-sm`}
          onClick={() => setTab(DATASETSECTIONS)}
        >
          Dataset sections
        </button>
        <button
          className={`${
            tab === INFORMATION && "bg-black-80 text-white"
          } transition-colors px-3 py-2 text-sm`}
          onClick={() => setTab(INFORMATION)}
        >
          Infos
        </button>
        <button
          className={`${
            tab === RAW_DATA && "bg-black-80 text-white"
          } transition-colors px-3 py-2 text-sm`}
          onClick={() => setTab(RAW_DATA)}
        >
          Raw
        </button>
      </nav>
      <div className="bg-white rounded-md p-6 border border-primary-black-50">
        {tab === INFORMATION && <Information dataset={dataset} />}
        {tab === DATASETSECTIONS && <DatasetSections dataset={dataset} />}
        {tab === RAW_DATA && <RawData dataset={dataset} />}
      </div>
    </div>
  );
};

const RawData = ({ dataset }) => {
  return (
    <div className="">
      <pre> {JSON.stringify(dataset, null, 2)}</pre>
    </div>
  );
};
