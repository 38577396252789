import React, { useState } from "react";

import Modal from "../modal";

import Information from "./information";
import Report from "./report";
import RawData from "./raw";
import Notes from "./notes";
import Rules from "./rules";

const INFORMATION = "INFORMATION";
const REPORT = "REPORT";
const RAW_DATA = "RAW_DATA";
const NOTES = "NOTES";
const RULES = "RULES";

export default ({ value, onChange, datasetId, handleChange }) => {
  const [tab, setTab] = useState(INFORMATION);
  if (!value) return <div />;

  return (
    <Modal isOpen={true} onClose={() => onChange()}>
      <nav className="flex items-center gap-2 pl-2">
        <button className={`${tab === INFORMATION && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(INFORMATION)}>
          Information
        </button>
        <button className={`${tab === REPORT && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(REPORT)}>
          Report
        </button>
        <button className={`${tab === NOTES && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(NOTES)}>
          Notes
        </button>
        <button className={`${tab === RULES && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(RULES)}>
          Rules
        </button>
        <button className={`${tab === RAW_DATA && "bg-black-80 text-white"} transition-colors px-3 py-2 text-sm`} onClick={() => setTab(RAW_DATA)}>
          Raw Data
        </button>
      </nav>
      <div className="bg-white rounded-md p-6 border border-primary-black-50 m-4">
        {tab === INFORMATION && <Information datasetId={datasetId} indicator={value} onChange={handleChange} />}
        {tab === REPORT && <Report indicator={value} />}
        {tab === RAW_DATA && <RawData indicator={value} />}
        {tab === NOTES && <Notes indicator={value} />}
        {tab === RULES && <Rules indicator={value} />}
      </div>
    </Modal>
  );
};
