import React, { useRef, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import api from "../../services/api";
import Autocomplete from "../Autocomplete";
import TextEditor from "../TextEditor";

export default ({ datasetSection, datasetId }) => {
  const [values, setValues] = useState(datasetSection);
  const [language, setLanguage] = useState("en");

  const onUpdate = async () => {
    const { data, ok } = await api.put(`/ressource_section/${datasetSection._id}`, values);
    if (!ok) return toast.error(data);
    setValues(data);
    toast.success("Updated!");
  };

  const onDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/ressource_section/${datasetSection._id}`);
    toast.success("Delete!");
    window.location.reload();
  };

  async function fetchSubCategories(SubCategoryFilter) {
    try {
      const nf = {
        sub_category: SubCategoryFilter,
        ressource_id: datasetId,
      };
      const { data } = await api.post("/ressource_section/search/subCategory", nf);
      return data.map((d) => d.en);
    } catch (error) {
      console.error("Erreur lors de la récupération des sous catégorie :", error);
      return [];
    }
  }

  const handleUpdateSubCategory = async (e) => {
    const payload = {
      sub_category: {
        en: e,
      },
    };
    const { data, ok } = await api.put(`/ressource_section/${datasetSection._id}`, payload);
    if (!ok) return toast.error(data);
    toast.success("Updated!");
  };

  return (
    <div>
      <div className="flex justify-end space-x-2 mt-4">
        <button className="btn btn-red" onClick={onDelete}>
          Delete the datasets section
        </button>
      </div>
      <div className="grid grid-cols-6 gap-4">
        <div className="flex flex-col col-span-3">
          <div className="text-sm font-medium mb-2">Title</div>
          <input
            // i want it to take the whole width
            className="input w-full"
            value={values.title.en}
            onChange={(e) => setValues({ ...values, title: { ...values.title, en: e.target.value } })}
            onBlur={onUpdate}
          />
        </div>
        <div className="flex flex-col col-span-3">
          <div className="text-sm font-medium mb-2">Sub-category</div>
          <Autocomplete fetchData={fetchSubCategories} handleUpdate={handleUpdateSubCategory} value={values.sub_category?.en} placeholderText={"Indicate the sub category"} />
        </div>
      </div>

      <hr className="mt-10 mb-6" />

      <div className="text-sm font-medium mb-2">Content</div>
      <div className="grid grid-cols-6 gap-4">
        <div className="flex flex-col col-span-6">
          <p className=" text-xs font-small p-2 rounded bg-gray-100 italic">
            Fill the content of a datasets section using the dropdown menu set to 'Normal'. If you wish to display a title and have it reflected in the page's table of contents,
            select 'Heading Four'.
          </p>
        </div>
        <div className="flex flex-col col-span-6">
          <TextEditor
            value={values.content?.en}
            onChange={(e) =>
              setValues({
                ...values,
                content: { ...values.content, en: e.target.value },
              })
            }
          />
        </div>
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <button className="btn btn-green" onClick={onUpdate}>
          Update Content
        </button>
      </div>
    </div>
  );
};
