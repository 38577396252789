import React, { useState, useRef, useEffect } from "react";

const Autocomplete = ({ fetchData, handleUpdate, value, placeholderText }) => {
  const [options, setOptions] = useState([]);
  const [isFocus, setIsFocus] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const containerRef = useRef(null);
  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleBlur = () => {
    setTimeout(() => {
      if (!containerRef.current.contains(document.activeElement)) {
        setIsFocus(false);
      }
    }, 0);
  };

  async function fetchOptions(searchFilter) {
    const data = await fetchData(searchFilter);
    setOptions(data);
  }

  const handleInputChange = (e) => {
    const { value } = e.target;
    setTempValue(value);
    fetchOptions(value);
  };

  const handleSelectOption = (option) => {
    setTempValue(option);
    handleUpdate && handleUpdate(option);
    handleUpdate && setOptions([]);
  };

  return (
    <div className="relative" onBlur={handleBlur} ref={containerRef}>
      <input
        type="text"
        className="border border-gray-300 rounded px-3 py-1 focus:outline-none focus:border-blue-500"
        placeholder={placeholderText}
        value={tempValue}
        onChange={handleInputChange}
        onFocus={(e) => {
          handleInputChange(e);
          setIsFocus(true);
        }}
      />
      {isFocus && (
        <ul className="absolute z-50 bg-white border border-gray-300 rounded w-full mt-1 py-1 max-h-56 overflow-y-auto shadow-xl" onMouseDown={(e) => e.preventDefault()}>
          {options.length > 0 &&
            options.map((option, index) => (
              <li
                key={index}
                className="px-3 py-1 cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  handleSelectOption(option);
                  setIsFocus(false);
                }}>
                {option}
              </li>
            ))}
          <li
            className="red px-3 py-1 cursor-pointer hover:bg-gray-100"
            onClick={() => {
              handleSelectOption(tempValue);
              setIsFocus(false);
            }}>
            Create
            <span className="text-green-500">{` "${tempValue}"`}</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
