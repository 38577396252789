import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useHistory, Link } from "react-router-dom";

import Loader from "../../../components/loader";

import api from "../../../services/api";

import Information from "./info";
import RawData from "./raw";
import CampaignIndicators from "./campaign";
import Questionnaires from "./questionnaires";

const INFORMATION = "INFORMATION";
const RAW_DATA = "RAW_DATA";
const CAMPAIGN_INDICATORS = "CAMPAIGN_INDICATORS";
const QUESTIONNAIRES = "QUESTIONNAIRES";

export default () => {
  const [campaign, setCampaign] = useState();
  const [tab, setTab] = useState(INFORMATION);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/campaign/${id}`);
        setCampaign(data);
      } catch (e) {
        toast.error(e.message);
      }
    })();
  }, [id]);

  if (!campaign) return <Loader />;

  return (
    <div className="grid md:grid-cols-12 w-full gap-x-4 gap-y-2">
      <div className="md:col-span-8">
        <nav className="flex items-center gap-2 pl-2">
          <button
            className={`${
              tab === INFORMATION && "bg-black-80 text-white"
            } transition-colors px-3 py-2 text-sm`}
            onClick={() => setTab(INFORMATION)}
          >
            Information
          </button>
          <button
            className={`${
              tab === QUESTIONNAIRES && "bg-black-80 text-white"
            } transition-colors px-3 py-2 text-sm`}
            onClick={() => setTab(QUESTIONNAIRES)}
          >
            Questionnaires
          </button>
          <button
            className={`${
              tab === CAMPAIGN_INDICATORS && "bg-black-80 text-white"
            } transition-colors px-3 py-2 text-sm`}
            onClick={() => setTab(CAMPAIGN_INDICATORS)}
          >
            Campaign Indicators
          </button>
          <button
            className={`${
              tab === RAW_DATA && "bg-black-80 text-white"
            } transition-colors px-3 py-2 text-sm`}
            onClick={() => setTab(RAW_DATA)}
          >
            Raw Data
          </button>
        </nav>
        <div className="bg-white rounded-md p-6 border border-primary-black-50">
          {tab === INFORMATION && <Information campaign={campaign} />}
          {tab === CAMPAIGN_INDICATORS && (
            <CampaignIndicators campaign={campaign} />
          )}
          {tab === RAW_DATA && <RawData campaign={campaign} />}
          {tab === QUESTIONNAIRES && <Questionnaires campaign={campaign} />}
        </div>
      </div>
      <div className="md:col-span-4 md:space-y-10 space-y-4 row-start-1 md:row-start-auto">
        <div className="bg-white rounded-lg border mb-2 divide-y">
          <div className="py-4 px-5">
            <Link
              className="text-sm text-app underline"
              to={`/questionnaire?campaign_id=${campaign._id}&role=user`}
            >
              See questionnaires
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
