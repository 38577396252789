import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import Information from "./information";
import Indicators from "./indicators";
import Template from "./template";

import api from "../../../services/api";

const INFORMATION = "INFORMATION";
const INDICATORS = "INDICATORS";
const RAW_DATA = "RAW_DATA";
const TEMPLATE = "TEMPLATE";

export default ({}) => {
  const [dataset, setDataset] = useState(null);
  const [tab, setTab] = useState(INDICATORS);

  const { id } = useParams();

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const { data } = await api.get(`/dataset/${id}`);
    setDataset(data);
  }

  if (!dataset) return <div />;

  return (
    <div>
      <nav className="flex items-center gap-2 pl-2">
        <button
          className={`${
            tab === INDICATORS && "bg-black-80 text-white"
          } transition-colors px-3 py-2 text-sm`}
          onClick={() => setTab(INDICATORS)}
        >
          Indicators
        </button>
        {dataset.type === "custom" && (
          <button
            className={`${
              tab === TEMPLATE && "bg-black-80 text-white"
            } transition-colors px-3 py-2 text-sm`}
            onClick={() => setTab(TEMPLATE)}
          >
            Template - {dataset.master_dataset_name}
          </button>
        )}
        <button
          className={`${
            tab === INFORMATION && "bg-black-80 text-white"
          } transition-colors px-3 py-2 text-sm`}
          onClick={() => setTab(INFORMATION)}
        >
          Infos
        </button>
        <button
          className={`${
            tab === RAW_DATA && "bg-black-80 text-white"
          } transition-colors px-3 py-2 text-sm`}
          onClick={() => setTab(RAW_DATA)}
        >
          Raw
        </button>
      </nav>
      <div className="bg-white rounded-md p-6 border border-primary-black-50">
        {tab === INFORMATION && <Information dataset={dataset} />}
        {tab === TEMPLATE && <Template dataset={dataset} />}
        {tab === INDICATORS && <Indicators dataset={dataset} />}
        {tab === RAW_DATA && <RawData dataset={dataset} />}
      </div>
    </div>
  );
};

const RawData = ({ dataset }) => {
  return (
    <div className="">
      <pre> {JSON.stringify(dataset, null, 2)}</pre>
    </div>
  );
};
