import React, { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import api from "../../services/api";

export default ({ indicator }) => {
  const [values, setValues] = useState(indicator);

  const onUpdate = async () => {
    const { data, ok } = await api.put(`/indicator/${indicator._id}`, values);
    if (!ok) return toast.error(data);
    toast.success("Updated!");
  };

  return (
    <div>
      <div className="w-full">
        <textarea
          className="input"
          rows="20"
          value={values.notes}
          onChange={(e) => setValues({ ...values, notes: e.target.value })}
        />
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <button className="btn btn-primary" onClick={onUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};
