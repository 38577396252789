import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";
import Pagination from "../../components/pagination";
import MultiSelect from "../../components/MultiSelect";

import api from "../../services/api";

export default () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const [questionnaires, setQuestionnaires] = useState();
  const [funds, setFunds] = useState();
  const [companies, setCompanies] = useState();
  const [campaigns, setCampaigns] = useState();
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 20,
    funds: urlParams.get("fund_id") ? [urlParams.get("fund_id")] : [],
    companies: urlParams.get("company_id") ? [urlParams.get("company_id")] : [],
    campaigns: urlParams.get("campaign_id")
      ? [urlParams.get("campaign_id")]
      : [],
  });

  async function getCampaigns() {
    const { data, ok } = await api.post("/campaign/search");
    if (!ok) return toast.error("Error!");
    setCampaigns(data);
  }

  async function getCompanies() {
    const { data, ok } = await api.post("/company/search");
    if (!ok) return toast.error("Error!");
    setCompanies(data);
  }

  async function getFunds() {
    const { data, ok } = await api.post("/fund/search");
    if (!ok) return toast.error("Error!");
    setFunds(data);
  }

  useEffect(() => {
    getFunds();
    getCompanies();
    getCampaigns();
  }, []);

  useEffect(() => {
    getQuestionnaires();
  }, [filter]);

  async function getQuestionnaires() {
    const { data, total } = await api.post("/questionnaire/search", {
      search: filter.search,
      page: filter.page,
      per_page: filter.per_page,
      funds: filter.funds,
      companies: filter.companies,
      campaigns: filter.campaigns,
    });
    setTotal(total);
    setQuestionnaires(data);
  }

  if (!questionnaires || !funds || !companies || !campaigns) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between space-x-6">
        <div className="flex gap-6 w-full justify-end">
          <MultiSelect
            values={filter.campaigns?.map((campaignId) => ({
              value: campaignId,
            }))}
            onSearch={(search) => setFilter({ ...filter, search })}
            onSelectedChange={(e) => {
              const ids = e.map((e) => e.value);
              setFilter({ ...filter, campaigns: ids });
            }}
            placeholder="Campaigns"
            options={campaigns.map((f) => ({ value: f._id, label: f.name }))}
            renderOption={(item) => <div>{item}</div>}
          />
          <MultiSelect
            values={filter.companies?.map((companyId) => ({
              value: companyId,
            }))}
            onSearch={(search) => setFilter({ ...filter, search })}
            onSelectedChange={(e) => {
              const ids = e.map((e) => e.value);
              setFilter({ ...filter, companies: ids });
            }}
            placeholder="Companies"
            options={companies.map((f) => ({ value: f._id, label: f.name }))}
            renderOption={(item) => <div>{item}</div>}
          />
          <MultiSelect
            values={filter.funds?.map((fundId) => ({ value: fundId }))}
            onSearch={(search) => setFilter({ ...filter, search })}
            onSelectedChange={(e) => {
              const ids = e.map((e) => e.value);
              setFilter({ ...filter, funds: ids });
            }}
            placeholder="Funds"
            options={funds.map((f) => ({ value: f._id, label: f.name }))}
            renderOption={(item) => <div>{item}</div>}
          />
          <SearchBar search={filter.search} setFilter={setFilter} />
        </div>
      </div>

      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden ">
                <thead className="bg-[#EFF2FE]">
                  <tr>
                    <th className="p-3 text-left text-primary-black-100 ">
                      Fund
                    </th>
                    <th className="p-3 text-left text-primary-black-100 ">
                      Company
                    </th>
                    <th className="p-3 text-left text-primary-black-100 ">
                      Dataset
                    </th>
                    <th className="p-3 text-left text-primary-black-100 ">
                      Campaign
                    </th>
                    <th className="p-3 text-left text-primary-black-100 ">
                      Name
                    </th>
                    <th className="p-3 text-left text-primary-black-100">
                      Created at
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {questionnaires.map((e) => (
                    <tr
                      key={e.email}
                      className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`}
                      onClick={() => history.push(`/questionnaire/${e._id}`)}
                    >
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {e.fund_name}
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {e.company_name}
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {e.dataset_name}
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {e.campaign_name}
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {e.name}
                      </td>
                      <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                        {(e.created_at || "")?.slice(0, 10)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-between items-center w-full p-3 text-primary-black-90 border-r border-primary-black-50 bg-[#EFF2FE] rounded-b-lg">
                <div>{`${(filter.page - 1) * filter.per_page + 1} à ${Math.min(
                  filter.page * filter.per_page,
                  total
                )} sur ${total} résultat(s)`}</div>
                <Pagination
                  per_page={filter.per_page}
                  total={total}
                  onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
                  onPrevious={() =>
                    setFilter((f) => ({ ...f, page: +f.page - 1 }))
                  }
                  currentPage={filter.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
