const MultiChoicesIndicator = ({ options, selected, title, classNameSelected, classNameNotSelected }) => (
  <div className="w-full h-full relative rounded-3xl border border-gray-300 p-10 flex justify-center flex-col gap-5 z-10">
    <h1 className="text-3xl font-semibold">{title}</h1>
    <div className="mt-5 flex flex-wrap gap-5  text-lg">
      {options.map((option) => (
        <p className={`${selected?.includes(option) ? classNameSelected : classNameNotSelected} py-3 rounded-2xl px-3`}>{option}</p>
      ))}
    </div>
  </div>
);

export default MultiChoicesIndicator;
